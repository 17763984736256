import React, { useRef, useEffect } from "react";
import "bootstrap-daterangepicker/daterangepicker.css"; // DatePicker styles
import $ from "jquery"; // Import jQuery
import "bootstrap-daterangepicker"; // Import the plugin
import moment from "moment"; // Import moment.js

const DateRangePicker = ({ onChange,place }) => {
  const pickerRef = useRef(null);
  let fromDate ;
  let toDate ;
  useEffect(() => {
    const options = {
      autoApply: true,
      autoUpdateInput: false,
      startDate: fromDate ? moment(fromDate, "DD/MM/YYYY") : moment(),
      endDate: toDate ? moment(toDate, "DD/MM/YYYY") : moment(),
      minDate: moment(),
      locale: {
        format: "DD/MM/YYYY",
      },
    };
  
    $(pickerRef.current).daterangepicker(options, function (start, end) {
      onChange({ fromDate: start.format("DD/MM/YYYY"), toDate: end.format("DD/MM/YYYY") });
      $(pickerRef.current).val(`${start.format("DD/MM/YYYY")} - ${end.format("DD/MM/YYYY")}`);
    });
  

  
    return () => {
      $(pickerRef?.current)?.data("daterangepicker")?.remove();
    };
  }, [onChange, fromDate, toDate]);

  return <input type="text" ref={pickerRef} className="daterangepicker_input" placeholder = {place ? place:"Select Date Range"} readOnly />;
};

export default DateRangePicker;
