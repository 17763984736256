import React from "react";
import Logo from "../Assets/Logo.svg";
import axios from "axios";
import { baseUrl } from "../index";
import SelectSearch from "react-select-search";
import { Button, Input,Card,CardBody } from "reactstrap";
import withRouter from "./withRouter";
import DateRangePicker from "./Datepicker";
import moment from "moment";
import MobileDatePickerCalendar from "./MobileDatePickerCalendar";
const currentLocation = window.location.pathname;

const navLinks = [
  { name: "Home", path: "#home" },
  { name: "Explore", path: "#explore" },
  // { name: "Rooms", path: "#rooms" },
  // { name: "About", path: "#about" },
  { name: "Contact", path: "#contact" },
];

class Toolbar extends React.Component {
  constructor() {
    super();
    this.state = {
      searchInput: "",
      fromDate: "",
      toDate: "",
      allHostels: [],
      allLocations: [],
      city: "",
      open: "0",
      nextDate: "",
      hostel: {},
      otherSearch: "",
    };
  }
  componentDidMount() {
    axios
      .get(`${baseUrl}/hostel/all-hostels`)
      .then((response) => {
        this.setState({ allHostels: response.data });
      })
      .catch((err) => {
        console.log(err.message);
      });
    axios
      .get(`${baseUrl}/hostel/hostel-location`)
      .then((response) => {
        this.setState({ allLocations: response.data });
      })
      .catch((err) => {
        console.log(err.message);
      });
    const { params,searchParams } = this.props.router;
    let city = params.location || searchParams.get("city");
    const defaultDates = this.getDefaultDates()
    const fromDate = searchParams.get("from") || defaultDates.fromDate;
    const toDate = searchParams.get("to") || defaultDates.toDate;
   
    const fromDateObj = moment(fromDate, "YYYY-MM-DD", true).isValid() ? new Date(fromDate) : new Date();
    const nextDay = new Date(fromDateObj);
    const otherSearchParams = searchParams.get("otherSearch");
    nextDay.setDate(nextDay.getDate() + 1);
    this.setState({ nextDate: nextDay.toISOString().split("T")[0] });
    if (currentLocation.startsWith("/search")) { 
      if(city === null || city === undefined){
        city = currentLocation;
        city = city.replace('/search/hostels-in-','');
      }
      this.setState({ searchInput: city, otherSearch: otherSearchParams });
    }
    if (currentLocation.startsWith("/hostel") ) {
      let hostelId = params.id ?? searchParams.get("hostel");
      if(hostelId == null){ 
          hostelId = currentLocation.replace("/hostel/", "");  
      }  
      axios
        .get(
          `${baseUrl}/hostel/hostel/${hostelId}?otherSearch=${searchParams.get("otherSearch")}`
        )
        .then((response) => {
          this.setState({
            hostel: response.data[0],
            searchInput: response.data[0].hostel_name,
          });
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    const formattedFromDate = moment(fromDate, "DD/MM/YYYY", true).isValid()
    ? fromDate
    : moment(fromDate).format("DD/MM/YYYY");

  const formattedToDate = moment(toDate, "DD/MM/YYYY", true).isValid()
    ? toDate
    : moment(toDate).format("DD/MM/YYYY");
    this.setState({ fromDate: formattedFromDate, toDate: formattedToDate, city: city },()=>console.log({formattedFromDate,formattedToDate}));
  }
   handleDate =(date) =>{
        // this.setState({fromDate:moment(date.fromDate, "DD/MM/YYYY").format("ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)"), toDate:moment(date.toDate, "DD/MM/YYYY").format("ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)")})
        console.log(date)
        this.setState({fromDate:moment(date.fromDate, "DD/MM/YYYY").format("ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)"), toDate:moment(date.toDate, "DD/MM/YYYY").format("ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)")})
      }
  getDefaultDates = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    
    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);

    // Format dates as YYYY-MM-DD
    const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    };
    // console.log({date:{tomorrow,dayAfterTomorrow}});
    return {
        fromDate: formatDate(tomorrow),
        toDate: formatDate(dayAfterTomorrow)
    };}
  render() {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const onChange = (e) => {
      const { name, value } = e.target;
      if (name === "fromDate") {
        const nextDay = new Date(value);
        nextDay.setDate(nextDay.getDate() + 1);
        this.setState({ nextDate: nextDay.toISOString().split("T")[0] });
      }
      this.setState({ [name]: value });
    };
    const onSubmit = () => {
      const { searchInput, fromDate, toDate, otherSearch } = this.state;
      console.log({ searchInput, fromDate, toDate, otherSearch })
      const formatDate = (date) => {
        if (!date) return "";
        let parsedDate = date;
        if (date.includes('/')) {
          parsedDate = moment(date, "DD/MM/YYYY");
        }
        parsedDate = new Date(new Date(parsedDate).setHours(12, 0, 0, 0)).toISOString().split("T")[0];
        console.log(parsedDate)

        return parsedDate;
      };
      const formattedFromDate = formatDate(fromDate);
      const formattedToDate = formatDate(toDate);
      if (currentLocation.startsWith("/search")) {
        window.location.href = `/search?city=${searchInput}&from=${formattedFromDate}&to=${formattedToDate}&otherSearch=${searchInput}`;
      } else {
        let hostelId = window.location.pathname.replace("/hostel/","");
        window.location.href = `/hostel?hostel=${hostelId}&from=${formattedFromDate}&to=${formattedToDate}&otherSearch=${otherSearch}`;
      }
    };
    const isHomePage = currentLocation === "/"
    const showSearchBar =
      currentLocation === "/hostel" || "/search";
    const { fromDate, toDate } = this.state;
    return (
      <div className="py-3 bg-white">
        <div className=" nav-bar">
          <div className="">
            <a href="/">
              <img
                src={Logo}
                alt="logo"
                className="d-none d-md-inline-block"
                style={{ height: "40px" }}
              />
              <img
                src={Logo}
                alt="logo"
                className="d-md-none d-inline-block"
                style={{ height: "25px" }}
              />
            </a>
          </div>
          {showSearchBar && !isHomePage && (
            <>
             <div
              className="d-inline-flex col-2 row row-cols-10 py-1 mx-auto mob-none"
              style={{borderRadius:'1rem',border:'1px solid'}}
              id="searchbar-nav"
            >
               <div className="col-auto p-0">
                <Button
                      onClick={onSubmit}
                      className=" d-flex align-items-center justify-content-center bg-transparent"
                      style={{ 
                        // backgroundColor: "black",
                        color:"black",
                        fontSize:"2rem",
                        border: "none",
                        borderRadius: "50%",
                        padding: "0.5rem 1rem"
                      }}
                    >
                      <i className="bi bi-search"></i>
                    </Button>
              </div>
              <div className="col-4" id="searchInput-cover">
                <SelectSearch 
                  options={this.state.allLocations}
                  name="searchInput"
                  search
                  onChange={(value) => {
                    this.setState({
                      searchInput: value,
                    });
                  }}
                  placeholder={this.state.searchInput}
                  // disabled={currentLocation.startsWith("/hostel")}
                />
              </div>
              <div
                className="col-6 custom-dates-index"
                style={{  }}
              >
                
                
                <DateRangePicker   onChange={this.handleDate} fromDate={this.state.fromDate} toDate={this.state.toDate}
                 place={this.state.fromDate && this.state.toDate 
                ?`${this.state.fromDate.replaceAll('/','-')} - ${this.state.toDate.replaceAll('/','-')}`
                  : "Select Date Range"}
                />
                
              </div>
               
            </div>

            <div
             className="d-sm-none d-inline-flex col-2 row row-cols-10 py-1 mx-auto align-items-center justify-content-between"
             
             id="searchbar-nav"
           >
             <div className="col-3" id="searchInput-cover">
               <SelectSearch 
                 options={this.state.allLocations}
                 name="searchInput"
                 search
                 onChange={(value) => {
                   this.setState({
                     searchInput: value,
                   });
                 }}
                 placeholder={this.state.searchInput}
                //  disabled={currentLocation === "/hostel"}
               />
             </div>
             <div
               className="col-6 custom-dates-index"
               style={{  }}
             >
               <div className="row row-cols-2 align-items-center justify-content-between">
                 <div className="col position-relative align-content-cente align-items-center">
                   <Input
                     min={new Date().toISOString().split("T")[0]}
                     className="border-0 custom-date-input m-0 p-0 bg-transparen opacity-0 position-absolute"
                     onChange={onChange}
                     value={this.state.fromDate}
                     placeholder="From"
                     name="fromDate"
                     type="date"
                   />
                   <div className="text-wrap">{fromDate}</div>
                 </div>
                 <div className="col position-relative align-content-center">
                   <Input
                     min={this.state.nextDate}
                     className="border-0 custom-date-input m-0 p-0 col bg-transparent opacity-0 position-absolute"
                     onChange={onChange}
                     value={this.state.toDate}
                     placeholder="To"
                     name="toDate"
                     type="date"
                   />
                   {toDate}
                 </div>
               </div>
             </div>
             <div className="col-auto p-0">
               <Button
                 onClick={onSubmit}
                 size="sm"
                 style={{ padding: "0.50rem 0.75rem" ,
                   backgroundColor: "black", 
                   border: "2px solid black", 
                   borderRadius: "50%"
                 }}
                 className="book-button"
               >
                 <i className="bi bi-search" style={{color:"white"}}></i>
               </Button>
             </div>
           </div>

            </>
          )}

          {!showSearchBar && currentLocation!== "/search" && (
            <div className="nav-links">
              {navLinks.map((links) => (
                <div className="single-link">
                  <a href={links.path}>{links.name}</a>
                </div>
              ))}
                {/* <img
                className="review-avatar"
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
              /> */}
            </div>
          )}
     
        </div>
      </div>
    );
  }
}

export default withRouter(Toolbar);
