import React, { Component } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class ProductImageGallery extends Component {
  toggleShowAll = () => {
    this.props.onClick();
  };
  render() {
    const { images } = this.props;

    const mainImage = images[0];
    const gridImages = images.slice(1, 5);
    const remainingCount = images.length - 5;

    return (
      <div className="product-gallery"> 
        <div className="main-image">
          <LazyLoadImage src={mainImage} alt="Main" effect="blur" onClick={this.toggleShowAll} />
        </div>

        <div className="thumbnail-grid">
          {gridImages.map((image, index) => (
            <div key={index} className="thumbnail" onClick={this.toggleShowAll}>
              <LazyLoadImage src={image} alt={`Thumbnail ${index + 1}`} effect="blur" />
            </div>
          ))}

          {remainingCount > 0 && (
            <div className="more-btn d-flex align-items-center justify-content-center" onClick={this.toggleShowAll}>
              <span>+{remainingCount} More</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ProductImageGallery;
