import React, { useState } from 'react';
import { Modal, Button, Label, Input, Alert } from 'reactstrap';
import Moment from 'react-moment';

const BookingPopup = ({ isOpen, toggle, bookingData, checkInDate, checkOutDate, days, totalAmount, hostel, onReserve, onChange }) => {
  const [showMore, setShowMore] = useState(false);
console.log("booking data",bookingData)
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="modal-dialog-centered">
      <div className="modal-content rounded-5 shadow" style={{backgroundColor:"#EBEAEA"}}>
        <div className="modal-body p-4">
        <button 
  className="position-absolute top-0 start-0 m-3 border-0 bg-light rounded"
  style={{ color: "#e10000", fontSize: "1.5rem" }} 
  onClick={toggle}>
  <i className="bi bi-chevron-left"></i>
</button>

          
          <div className="fw-bold h4 text-dark mb-3" style={{textAlign:"center"}}>Booking Summary</div>
          <div className="fw-bold mb-3" style={{ color: "#ED452F",textAlign:"center" }}>
            {days} {days === 1 ? "Night" : "Nights"} starting from <span className="me-1"></span>
            <Moment format="DD MMM YYYY">{checkInDate}</Moment>
          </div>

          <div className="row row-cols-lg-2 row-cols-1 g-3 mb-3" style={{justifyContent:"space-evenly",gap:"2rem"}}>
            <div className="col" style={{width:"40%"}}>
              <Label className="fw-bold">Move In</Label>
              {/* <Input type="date" style={{backgroundColor:"white",borderRadius:"0.5rem"}} disabled value={checkInDate} /> */}
              <div style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                backgroundColor: "white",
                borderRadius: "0.5rem",
                padding: "4px 8px",
                border: "1px solid #ccc",
                width: "fit-content"
              }}>
                <i className="bi bi-calendar2-fill" style={{ fontSize: "1.2rem", color: "#555" }}/>
                <div >
                {checkInDate.split("-").reverse().join("-")}
                </div>
              </div>

            </div>
            <div className="col" style={{width:"40%"}}>
              <Label className="fw-bold">Move Out</Label>
              {/* <Input type="date" style={{backgroundColor:"white",borderRadius:"0.5rem"}} disabled value={checkOutDate} /> */}
              <div style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                backgroundColor: "white",
                borderRadius: "0.5rem",
                padding: "4px 8px",
                border: "1px solid #ccc",
                width: "fit-content"
              }}>
                <i className="bi bi-calendar2-fill" style={{ fontSize: "1.2rem", color: "#555" }}></i>
                <div >
                {checkOutDate.split("-").reverse().join("-")}
                </div>
              </div>
            </div>
          </div>

          {Object.keys(bookingData).length !== 0 && (
            <div>
              {Object.keys(bookingData).map((roomType, index) => (
                <div key={index} className="bg-white p-3 mb-3 shadow-sm" style={{borderRadius:"1rem"}}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="mb-1 fw-bold">{roomType}</h6>
                      <div className="text-muted">
                        Rs. {bookingData[roomType].cost} × {bookingData[roomType].totalRooms} Rooms
                      </div>
                    </div>
                    <div className="fw-bold">Rs. {bookingData[roomType].cost * bookingData[roomType].totalRooms}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>Discount:</div>
                    <div className="fw-bold">Rs. {bookingData[roomType].vendorDiscount?.toFixed(2)}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>Tax:</div>
                    <div className="fw-bold">
                      Rs. {((bookingData[roomType].cost * bookingData[roomType].totalRooms - bookingData[roomType].vendorDiscount) * 0.12).toFixed(2)}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>Additional Discount:</div>
                    <div className="fw-bold">
                      Rs. {(bookingData[roomType].thcDiscount).toFixed(2)}
                    </div>
                  </div>
                  {/* <hr className="hr mb-3" /> */}
                </div>
              ))}
              <div className="d-flex justify-content-between mb-3">
                <div className="fw-bold">Total</div>
                <div className="fw-bold">Rs. {totalAmount.toFixed(2)}</div>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <div className="fw-bold">Payable Now</div>
                <div className="fw-bold">Rs. {(totalAmount * (hostel[0]?.payable_percent / 100)).toFixed(2)}</div>
              </div>
              <div className="d-flex justify-content-center"> 
              <Button className="w-50 rounded-pill mb-3" onClick={onReserve} style={{backgroundColor:"#FD590C"}} >
                Continue booking
                </Button>
              </div>
              <Alert size="sm" color="info">You can apply coupons while checkout.</Alert>
            </div>
          )}
          {Object.keys(bookingData).length === 0 && (
            <a href="#select-rooms" className="btn w-100 mb-3 bg-dark-o text-white">Select room</a>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BookingPopup;
