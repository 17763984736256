export const rateCalculator = (allHostels) => {
    return [...new Set(allHostels.map(item => item.rating))]
}

export const amenityCalculator = (allHostels) => {
    return [...new Set(allHostels.flatMap(item => item.amenities))]
}

export const amenityCount = (allHostels) => {
    const amenityCount = {};

    allHostels.forEach(hostel => {
        hostel.amenities.forEach(amenity => {
            amenityCount[amenity] = (amenityCount[amenity] || 0) + 1;
        });
    });

    return amenityCount;
}

export const ratingValueCalculator = (allHostels) => {
    if (!allHostels || allHostels.length === 0) {
        return { minRating: 0, maxRating: 0 };
    }

    const ratings = allHostels.map(item => item.rating);
    const minRating = parseInt(Math.min(...ratings));
    const maxRating = parseInt(Math.max(...ratings));

    return { minRating, maxRating };
};


export const priceCalculator = (allHostels) => {
    if (!allHostels || allHostels.length === 0) {
        return { minPrice: 0, maxPrice: 0 };
    }

    const prices = allHostels.map(item => item.minimumAvgPrice);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    return { minPrice, maxPrice };
};

export const roomTypeCalculator = (allHostels) => {
    return [...new Set(allHostels.map(item => item.roomType))]
}

