import React from "react";
import {
    Card,
    CardBody,
    Input,
    Accordion,
    AccordionItem,
    AccordionBody,
    AccordionHeader,
} from "reactstrap";
import Footer from "../Components/Footer"
import withRouter from "../Components/withRouter"
import axios from "axios";
import { baseUrl } from "../index"
import SelectSearch from "react-select-search";
import "../App.css"
import "./styles/SearchPage.css"
import { Slider } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import MapComponent from "../Components/SearchMap";
import StarIcon from "@mui/icons-material/Star";
import { amenityCalculator, amenityCount, priceCalculator, rateCalculator, ratingValueCalculator } from "../Utils/searchFilters";
import { Card as HostelCardContainer, CardMedia, Box, Typography, Button, Collapse } from "@mui/material";
import { pink, red } from "@mui/material/colors";
import { Helmet } from "react-helmet";
import faq from "../Assets/faq.png";
import randomColor from "randomcolor";
import MyMap from "../Components/SearchMap";
import BookCardMobile from "../Components/BookCardMobile";
import { useNavigate } from "react-router-dom";

class SearchPage extends React.Component {
    constructor() {
        super()
        this.state = {
            fromDate: "",
            toDate: "",
            searchInput: "",
            otherSearch: "",
            amenity: "",
            allHostels: [],
            filteredHostels: [], 
            amenityFilter: [],
            ratingFilter: [],
            searchText: "", // New: To store search_text
            faq: "",
            open: false,
            faqQuestions: [],
            faqAnswers: [],
            priceFilterValue: 0,
            amenityFilterValue: [],
            ratingFilterValue: null,
            loading: true,
            isLaptop: window.matchMedia("(min-width: 1024px)").matches, // Check initial screen size
            isMobile: window.matchMedia("(max-width: 768px)").matches,
            showMore: false,
        }
    }
        getDefaultDates = () => {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            
            const dayAfterTomorrow = new Date();
            dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
    
            // Format dates as YYYY-MM-DD
            const formatDate = (date) => {
                return date.toISOString().split('T')[0];
            };
            // console.log({date:{tomorrow,dayAfterTomorrow}});
            return {
                fromDate: formatDate(tomorrow),
                toDate: formatDate(dayAfterTomorrow)
            };}
    

    componentDidMount() {
        console.log("Mounted again")
     const { params, searchParams } = this.props.router;
    let city, fromDate, toDate, amenity, otherSearch;
    // URL pattern /search/:location
    if (params.location) {
        city = params.location;
    } else {
        city = searchParams.get("city");
    }
    fromDate = searchParams.get("from");
    toDate = searchParams.get("to");
    amenity = searchParams.get("amenity");
    otherSearch = searchParams.get("otherSearch");
    this.mediaQuery = window.matchMedia("(min-width: 1024px)");
    this.mediaQuery.addEventListener("change", this.handleResize);
    this.mediaQueryList = window.matchMedia("(max-width: 768px)");
    this.mediaQueryList.addEventListener("change", this.updateScreenSize);
    // If dates are not provided or check-in date is in the past, use default dates
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const needsDefaultDates = !fromDate || !toDate || (new Date(fromDate) < today);
    
    if (needsDefaultDates) {
        const defaultDates = this.getDefaultDates();
        fromDate = defaultDates.fromDate;
        toDate = defaultDates.toDate;
            
        // Update URL with the new dates
        // const currentUrl = new URL(window.location.href);
        // currentUrl.pathname = `search/best-hostels-in-${city}`;
        // // currentUrl.searchParams.set('from', fromDate);
        // // currentUrl.searchParams.set('to', toDate);
        // window.history.replaceState({}, '', currentUrl);
    }
    if (city && city.startsWith("hostels-in-")) {
        city = city.replace("hostels-in-", "");  // Remove duplicate prefix
    }    
    const newPath = `/search/hostels-in-${city}`;
    const currentUrl = new URL(window.location.href);
    if (currentUrl.pathname !== newPath) {
        window.history.replaceState({}, '', newPath);
    }
    // console.log({fromDate,toDate,amenity,city,otherSearch});
    this.setState({
        searchInput: city || "",
        amenity: amenity || "",
        fromDate,
        toDate,
        otherSearch: otherSearch || city
    }, this.fetchSearchResults);
    }

    componentDidUpdate(prevProps) {
        // Update meta tags when location or dates change
        const { params } = this.props.router;
        if (params.location !== prevProps.router.params.location ||
            params.dates !== prevProps.router.params.dates) {
            this.updateMetaTags();
        }
    }

    componentWillUnmount() {
        console.log("it is unmounted")
        this.mediaQuery.removeEventListener("change", this.handleResize);
        this.mediaQueryList.removeEventListener("change", this.updateScreenSize);
    }

    updateMetaTags = () => {
        const { params,searchParams } = this.props.router;
        const { searchText } = this.state;
        let city = params.location?params.location:searchParams.get("city");
        document.title = `Top Hostels in ${city} | The Hostel Co`;
        
        // Update meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute(
                "content",
                searchText || `Find the best hostels in ${params.location}. Book your stay today!`
            );
        }

        // Add schema markup for better SEO
        const schema = {
            "@context": "https://schema.org",
            "@type": "SearchResultsPage",
            "about": {
                "@type": "LodgingBusiness",
                "name": `Hostels in ${city}`,
                "location": {
                    "@type": "Place",
                    "name": city
                }
            }
        };

        const scriptTag = document.querySelector('#search-schema');
        if (scriptTag) {
            scriptTag.textContent = JSON.stringify(schema);
        } else {
            const script = document.createElement('script');
            script.id = 'search-schema';
            script.type = 'application/ld+json';
            script.textContent = JSON.stringify(schema);
            document.head.appendChild(script);
        }
    }

    handleResize = (event) => {
        this.setState({ isLaptop: event.matches });
      };
    
    updateScreenSize = (event) => {
        this.setState({ isMobile: event.matches });
      };
      toggleShowMore = () => {
        this.setState(prevState => ({
          showMore: !prevState.showMore,
        }));
      };
    fetchSearchResults = () => {
        console.log("inside fetchsearchresults")
        const { searchInput, amenity, fromDate, toDate, otherSearch } = this.state;
        
        let apiUrl = `${baseUrl}/inventory/search-results?`;
        apiUrl += `location=${searchInput}&amenity=${amenity}&checkInDate=${fromDate}&checkOutDate=${toDate}&otherSearch=${otherSearch}`;
        this.setState({allHostels: [], filteredHostels: [], loading: true });

        axios.get(apiUrl)
            .then(response => {
                const hostels = response.data.hostels || [];
                console.log("hostel response",hostels)
                const seodata = response.data.seo_data || {};
                const mapData = response.data.mapData || [];
                const mapCenter = response.data.mapCenter || [];
                const mapZoom = response.data.mapZoom || 10;

                // Parse FAQ data
                const faqData = seodata.faq || '';
                const faqPairs = faqData.split('Q:').filter(pair => pair.trim() !== '');
                const questions = [];
                const answers = [];

                faqPairs.forEach(pair => {
                    const [question, answer] = pair.split('A:');
                    if (question && answer) {
                        const cleanedQuestion = question.replace(/;/g, '').trim();
                        const cleanedAnswer = answer.replace(/;/g, '').trim();
                        questions.push(cleanedQuestion);
                        answers.push(cleanedAnswer);
                    }
                });

                if (!hostels.length) {
                    this.setState({
                        faqQuestions: questions,
                        faqAnswers: answers,
                        searchText: seodata.search_text || "",
                        loading: false
                    });
                    return;
                }

                 // Fetch reviews and merge them with hostels
                axios.get(`${baseUrl}/hostel/reviews-all`)
                    .then(reviewResponse => {
                        const reviews = reviewResponse.data || [];
                        // console.log("Reviews:", reviews);
        
                        const reviewMap = {};
                        reviews.forEach(review => {
                            reviewMap[review.id] = {
                                ratings: review.rating || 0, 
                                userRatingCount: review.userRatingCount || 0
                            };
                        });
        
                        // Merge reviews with hostels
                        const updatedHostels = hostels.map(hostel => ({
                            ...hostel,
                            ratings: reviewMap[hostel.id]?.ratings || 0,
                            userRatingCount: reviewMap[hostel.id]?.userRatingCount || 0
                        }));
                        // console.log(updatedHostels)
                        
                        const amenityFilter = amenityCalculator(updatedHostels);
                        const ratingFilter = rateCalculator(updatedHostels);
        
                        this.setState({
                            allHostels: updatedHostels,
                            // allHostels: Array(5).fill(updatedHostels).flat(),
                            filteredHostels: updatedHostels,
                            mapData,
                            mapCenter,
                            mapZoom,
                            amenityFilter,
                            ratingFilter,
                            searchText: seodata.search_text || "",
                            faqQuestions: questions,
                            faqAnswers: answers,
                            loading: false
                        }, this.updateMetaTags);
                    })
                    .catch(err => {
                        this.setState({ loading: false });
                        console.log("Review fetch error:", err.message);
                    });
        
            })
            .catch(err => {
                this.setState({ loading: false });
                console.log(err.message);
            });
    }

    // generateUniqueColors = (count) => {
    //     const colors = new Set();
    //     while (colors.size < count) {
    //       colors.add(
    //         randomColor({
    //           luminosity: "light"
    //         })
    //       );
    //     }
    //     return Array.from(colors);
    //   };
    generateUniqueColors = (count) => {
        const predefinedColors = ["#2B67F6", "#27AE60", "#EB5757"]; // The specific colors as per figma file
        const colors = new Set(predefinedColors); // Initialize with predefined colors

        // additional colors if needed 
        while (colors.size < count) {
            colors.add(
                randomColor({
                    luminosity: "dark",
                })
            );
        }

        return Array.from(colors).slice(0, count);
    };


    render() {
        const { loading, faqQuestions, faqAnswers } = this.state

        const onHostel = (id) => {
            const { fromDate, toDate } = this.state
            window.location.href = `/hostel?hostel=${id}&from=${fromDate}&to=${toDate}`
            // this.props.router.navigate(`/hostel?hostel=${id}&from=${fromDate}&to=${toDate}`, { replace: true });
        }
        const overallFilterLaptop = (hostels, filters) => {
            let filteredHostels = [...hostels];
        
            // Filtering by Price (Show only hostels within the selected range)
            if (filters.priceFilterValue) {
                filteredHostels = filteredHostels.filter(hostel => 
                    hostel.minimumAvgPrice <= filters.priceFilterValue
                );
            }
        
            // Filtering by Selected Amenities (ALL selected amenities must be present)
            if (filters.amenityFilterValue.length > 0) {
                filteredHostels = filteredHostels.filter(hostel =>
                    filters.amenityFilterValue.every(amenity => hostel.amenities.includes(amenity))
                );
            }
        
            // Filtering by Selected Rating
            if (filters.ratingFilterValue) {
                filteredHostels = filteredHostels.filter(hostel => hostel.rating >= filters.ratingFilterValue);
            }
        
            return filteredHostels;
        };
        const overallFilter = (hostels, filters) => {
            let filteredHostels = [...hostels];
    
            // Sorting by Price
            if (filters.priceFilterValue === "LH") {
                filteredHostels.sort((a, b) => a.minimumAvgPrice - b.minimumAvgPrice);
            } else if (filters.priceFilterValue === "HL") {
                filteredHostels.sort((a, b) => b.minimumAvgPrice - a.minimumAvgPrice);
            }
            if (filters.amenityFilterValue) {
                filteredHostels = filteredHostels.filter(hostel =>
                    hostel.amenities.includes(this.state.amenityFilterValue)
                );
            }
            // if (filters.ratingFilterValue) {
            //     filteredHostels = filteredHostels.filter(hostel =>
            //         hostel.ratingFilter == this.state.ratingFilterValue
            //     );
            // }
            return filteredHostels;
        };    
        const onChangeLaptop = (e, newValue = null) => {
            console.log("THIS IS ONCHANGE");
            const { name, value, checked } = e.target;
            
            this.setState((prevState) => {
                let updatedState = { ...prevState };
        
                if (name === "amenityFilterValue") {
                    let updatedAmenities = Array.isArray(prevState.amenityFilterValue) ? [...prevState.amenityFilterValue] : [];
        
                    if (checked) {
                        if (!updatedAmenities.includes(value)) {
                            updatedAmenities.push(value);
                        }
                    } else {
                        updatedAmenities = updatedAmenities.filter(item => item !== value);
                    }
        
                    updatedState.amenityFilterValue = updatedAmenities;
                } else if (name === "ratingFilterValue") {
                    updatedState.ratingFilterValue = prevState.ratingFilterValue === Number(value) ? null : Number(value);
                } else if (name === "priceFilterValue" || newValue !== null) {
                    updatedState.priceFilterValue = newValue !== null ? newValue : Number(value); // Use slider value if provided
                } else {
                    updatedState[name] = value;
                }
        
                return updatedState;
            }, () => {
                console.log("Updated State:", this.state);
        
                const Filters = {
                    priceFilterValue: this.state.priceFilterValue, // Numeric price range
                    ratingFilterValue: this.state.ratingFilterValue,
                    amenityFilterValue: this.state.amenityFilterValue
                };
        
                const filteredHostels = overallFilterLaptop(this.state.allHostels, Filters);
                this.setState({ filteredHostels });
            });
        };
        
           
        const onChange = (e) => {
            const { name, value } = e.target;
            const { allHostels, priceFilterValue, ratingFilterValue, amenityFilterValue, searchText, faq } = this.state;

            this.setState({ [name]: value }, () => {
                const Filters = {
                    priceFilterValue: name === 'priceFilterValue' ? value : priceFilterValue,
                    ratingFilterValue: name === 'ratingFilterValue' ? value : ratingFilterValue, 
                    amenityFilterValue: name === 'amenityFilterValue' ? value : amenityFilterValue,
                };

                const filteredHostels = overallFilter(allHostels, Filters);
                this.setState({ filteredHostels });
            });
        };    

        const toggle = (id) => {
            if (this.state.open === id) {
                this.setState({ open: "" });
            } else {
                this.setState({ open: id });
            }
        };


        if (loading) {
            return (
                <div className="loading-frame">
                    <div className="spinner"></div>
                    <span className="loading-text">Loading...</span>
                </div>
            );
        }
        const amenities = this.state.filteredHostels[0]?.amenities || [];
        const uniqueColors = this.generateUniqueColors(amenities.length);
        const { minPrice, maxPrice } = priceCalculator(this.state.allHostels);
        const {minRating,maxRating} = ratingValueCalculator(this.state.allHostels);
        const amenities1 = amenityCount(this.state.allHostels);
        const first4Amenities = Object.entries(amenities1).slice(0, 4);
        const remainingAmenities = Object.entries(amenities1).slice(4);
        return (
            <div className=" search-whole-page">
                <Helmet>
                    <title>Best Hostels in {this.state.searchInput?.charAt(0).toUpperCase() + this.state.searchInput?.slice(1).toLowerCase()} | TheHostelCo for Solo & Party Trips</title>
                    <meta
                        name="description"
                        content={`Discover TheHostelCo's top hostels in ${this.state.searchInput?.charAt(0).toUpperCase() + this.state.searchInput?.slice(1).toLowerCase()}, perfect for solo travelers and party lovers. Explore Anjuna and North Goa's best backpacker spots. Book now!`}
                    />
                    <h1>Explore Top-Rated Hostels in {this.state.searchInput?.charAt(0).toUpperCase() + this.state.searchInput?.slice(1).toLowerCase()} with TheHostelCo</h1>
                </Helmet>
                <div className="search-page-container search-container-cover">
                    <div className="main-container" style={{ textAlign: "left" }}>
                        <div className="text-black fw-semibold fw-lg-semibold h2 mb-3">
                            {this.state.filteredHostels.length}
                            {this.state.filteredHostels.length <= 1 ? " hostel" : " hostels"} found in {this.state.searchInput
                                ? this.state.searchInput.charAt(0).toUpperCase() + this.state.searchInput.slice(1).toLowerCase()
                                : this.state.otherSearch}
                        </div>
                        {this.state.allHostels.length === 0 &&
                            <div>
                                No hostel is available
                            </div>}
                    </div>
{this.state.isLaptop && (
                    <div className="d-flex">
                <div className="left-sidebar p-3 new-font" style={{ width: "250px", background: "rgba(252, 252, 252, 1)", borderRadius: "8px" }}>
                    <h5 className="fw-bold">EVENT TYPE</h5>
                    <hr style={{ border: "0.5px solid #rgba(213, 213, 213, 1)", width: "100%" }} />
                    <div>
        <ul>
          {/* Render first 4 amenities */}
          {first4Amenities.map(([amenity, count], index) => (
            <li key={index} 
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}
                className="amenity-item"
            >
              <div>
                <input 
                  type="checkbox" 
                  name="amenityFilterValue" 
                  className="me-2 amenity-checkbox"
                  value={amenity} 
                  checked={this.state.amenityFilterValue.includes(amenity)}
                  onChange={onChangeLaptop} 
                />
                <label htmlFor={amenity} className="amenity-label">{amenity}</label>
              </div>
              <span className="amenity-count">{count}</span>
            </li>
          ))}

          {/* Show "Show more" button if there are remaining amenities */}
          {remainingAmenities.length > 0 && !this.state.showMore && (
            <li>
              <button onClick={this.toggleShowMore}   style={{
    marginTop: '10px',
    padding: '10px 20px', // Larger padding for a better button size
    backgroundColor: "rgba(253, 89, 12, 1)", // Beautiful blue color
    color: '#fff', // White text color
    border: 'none', // No border
    borderRadius: '25px', // Rounded corners for a pill shape
    fontSize: '14px', // Adjust font size for better readability
    fontWeight: 'bold', // Make text bold
    cursor: 'pointer', // Change cursor to indicate clickable
    transition: 'background-color 0.3s, transform 0.3s', // Smooth transition effects
  }}>
                Show more
              </button>
            </li>
          )}

          {/* Render remaining amenities if "Show more" is clicked */}
          {this.state.showMore && remainingAmenities.map(([amenity, count], index) => (
            <li key={index} 
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}
                className="amenity-item"
            >
              <div>
                <input 
                  type="checkbox" 
                  name="amenityFilterValue" 
                  className="me-2 amenity-checkbox"
                  value={amenity} 
                  checked={this.state.amenityFilterValue.includes(amenity)}
                  onChange={onChangeLaptop} 
                />
                <label htmlFor={amenity} className="amenity-label">{amenity}</label>
              </div>
              <span className="amenity-count">{count}</span>
            </li>
          ))}
{this.state.showMore && (
            <li>
              <button onClick={this.toggleShowMore} style={{
    marginTop: '10px',
    padding: '10px 20px', // Larger padding for a better button size
    backgroundColor: "rgba(253, 89, 12, 1)", // Beautiful blue color
    color: '#fff', // White text color
    border: 'none', // No border
    borderRadius: '25px', // Rounded corners for a pill shape
    fontSize: '14px', // Adjust font size for better readability
    fontWeight: 'bold', // Make text bold
    cursor: 'pointer', // Change cursor to indicate clickable
    transition: 'background-color 0.3s, transform 0.3s', // Smooth transition effects
  }}>
                Show less
              </button>
            </li>
          )}
        </ul>
      </div>
<br></br>
<hr style={{ border: "0.5px solid #rgba(213, 213, 213, 1)", width: "100%" }} />
                    
                    <h5 className="fw-bold mt-3">ADVANCE SEARCH</h5>
                    <hr style={{ border: "0.5px solid #rgba(213, 213, 213, 1)", width: "100%" }} />

                    <label className="d-block">Filter by Price</label>
<div>
                <Slider
                    value={this.state.priceFilterValue}
                    onChange={(event, newValue) => onChangeLaptop(event, newValue)}
                    min={minPrice}
                    max={maxPrice}
                    sx={{
                        color: "rgba(253, 89, 12, 1)", // Orange track
                        "& .MuiSlider-thumb": { backgroundColor: "rgba(253, 89, 12, 1)" }, // Orange thumb
                        "& .MuiSlider-track": { backgroundColor: "rgba(253, 89, 12, 1)" }, // Orange left side
                        "& .MuiSlider-rail": { backgroundColor: "#ddd" } // Grey right side
                    }}
                />
                <div className="d-flex justify-content-between">
                    <span>Min: Rs. {minPrice}</span>
                    <span>Max: Rs. {maxPrice}</span>
                </div>
            </div>

<br></br>
<hr style={{ border: "0.5px solid #rgba(213, 213, 213, 1)", width: "100%" }} />

                    <h5 className="fw-bold mt-3">RATING WISE</h5>
                    <hr style={{ border: "0.5px solid #rgba(213, 213, 213, 1)", width: "100%" }} />

                    <div className="d-flex">
                        {Array.from({ length: maxRating - minRating + 1 }, (_, index) => {
                            let ratingValue = minRating + index;
                            let isSelected = this.state.ratingFilterValue === ratingValue;
                            return (
                                <button 
                                key={ratingValue} 
                                name="ratingFilterValue"
                                value={ratingValue}
                                // className={`btn me-2 ${this.state.ratingFilterValue === ratingValue ? "btn-warning" : "btn-outline-secondary"}`}
                                className="btn me-2"
                                style={{
                                    backgroundColor: isSelected ? "rgba(253, 89, 12, 1)" : "rgba(226, 226, 226, 1)",
                                    color: isSelected ? "#fff" : "#000",
                                    width:"308x",
                                    height:"36px",
                                    radius:"8px"
                                }}
                                onClick={(e) => onChangeLaptop(e)}
                            >
                                {ratingValue}
                            </button>
                            );
                        })}
                    </div>
                </div>
                <div
  style={{
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)", // ✅ Max 3 columns
    gridAutoRows: "min-content", // ✅ Rows only take up required space, preventing stretching
    gap: "16px",
    alignItems: "start", // ✅ Moves items up, preventing empty gaps
    width: "100%", // ✅ Full width usage
  }}
>
                {this.state.filteredHostels.map(eachCard => {
                                return (
                <HostelCardContainer       sx={{
                    maxWidth: 393,
                    maxHeight: 294,
                    // gridAutoRows: "294px", // ✅ Force each row to be exactly 294px
                    overflow: "hidden",
                    position: "relative",
                    ml: 3,
                    mt: 3,
                    borderRadius: "16px",
                    cursor: "pointer",
                    "&:hover .click-icon": { opacity: 1 },
                  }} onClick={() => onHostel(eachCard.id)}>
      
      {/* Image */}
      <CardMedia
    component="img"
    height="294"
    image={eachCard.thumbnail}
    alt="Hostel Room"
    sx={{
        width: "393px",
        borderRadius: "16px", // Medium curve (Adjust as needed)
    }}
/>

      {/* Top-Right Rating */}
      <Box sx={{ position: "absolute", top: 10, right: 10, display: "flex", alignItems: "center", gap: 0.5, px: 1, py: 0.5, borderRadius: 1 }}>
        <Typography variant="body2" sx={{ color: "rgba(14, 131, 69, 1)", fontWeight: "bold" }}>{eachCard.rating} </Typography>
        <StarIcon sx={{ color: "rgba(14, 131, 69, 1)", fontSize: 18 }} />
      </Box>

      {/* Top-Right Review Count */}
      <Box sx={{ position: "absolute", top: 40, right: 10 }}>
        <Typography variant="body2" sx={{ fontWeight: "bold", color: "black" }}>
          {eachCard.total_reviews} ratings on google
        </Typography>
      </Box>

      {/* Bottom Overlay */}
      <Box sx={{ position: "absolute", bottom: 0, left: 0, width: "100%", bgcolor: "rgba(0,0,0,0.5)", color: "white", p: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {eachCard.hostel_name}
        </Typography>
        <Typography variant="h6" sx={{fontWeight: "bold", color: "gray.300" }}>
          {eachCard.location}
        </Typography>
        {eachCard.amenities.map(eachAmenity => {
            return (
<Box
  sx={{
    minWidth: "50px", // Further reduce pill width
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    px: 0.8, // Decrease horizontal padding
    py: 0.1, // Decrease vertical padding
    borderRadius: "25px", // Keep pill shape, but slightly smaller
    fontWeight: "normal", // Make text even thinner
    fontSize: "0.62rem", // Further reduce text size
    boxShadow: "none", // Remove any shadow
    mt: 0.5, // Reduce spacing
    mr: 0.3,
    mb: 0.3,
    whiteSpace: "nowrap",
    overflow: "hidden",
    bgcolor: "rgba(233, 233, 233, 1)", 
    color: "rgba(93, 93, 93, 1)",
    textOverflow: "ellipsis",
  }}
>
    {eachAmenity}
  </Box>
            )
        })}
      </Box>
      <Box sx={{ position: "absolute", bottom: 10, right: 16, textAlign: "right" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "white" }}>
          Rs {eachCard.minimumAvgPrice}
        </Typography>
        <Typography
  variant="h6"
  sx={{
    color:"white",
    fontWeight: "bold",
    cursor: "pointer", // 👈 Makes it clickable
    "&:hover": {
      textDecoration: "underline", // 👈 Adds an underline on hover
    },
  }}
  onClick={() => console.log("Book Now clicked!")} // 👈 Handle click action
>
  Book Now
</Typography>
      </Box>

    </HostelCardContainer>
                                )
                            })}
                            </div>
                </div>
)}
<>
{this.state.isMobile && (
    <>
                    <div className=" flex items-center justify-center py-4">
                        <div className=" main-container d-flex gap-3 flex-wrap" style={{ justifyContent: "flex-start" }} id="search-filter">
                            <Input onChange={onChange} value={this.state.priceFilterValue} name="priceFilterValue" style={{ borderRadius: "0.5rem", width: "max-content", direction: "ltr" }} type="select">
                                <option value="">Price</option>
                                <option value="LH">Price - low to high</option>
                                <option value="HL">Price - high to low</option>
                            </Input>
                            <Input onChange={onChange} value={this.state.amenityFilterValue} name="amenityFilterValue" style={{ borderRadius: "0.5rem", width: "max-content" }} type="select">
                                <option value="">Amenities</option>
                                {this.state.amenityFilter.map(eachAmenity => {
                                    return (
                                        <option value={eachAmenity} key={eachAmenity}>{eachAmenity}</option>
                                    )
                                })}
                            </Input>
                            <Input onChange={onChange} value={this.state.ratingFilterValue} name="ratingFilterValue" style={{ borderRadius: "0.5rem", width: "max-content" }} type="select">
                                <option value="">Rating</option>
                                {this.state.ratingFilter.map(eachRating => {
                                    return (
                                        <option value={eachRating} key={eachRating}>{eachRating}</option>
                                    )
                                })}
                            </Input>
                            
                        </div>
                    </div>

                    <div className=" main-container d-flex row row-cols-1 row-cols-lg-2 g-3 mb-3" id="search-cards-mob-cover" style={{ width: "92%" }}>
                        <div className="col" id="search-page-card-def"
                            style={{
                                overflowY: "auto",
                                maxHeight: "28rem",
                                padding: "0px 10px",
                                scrollbarWidth: "thin",    //  Thin scrollbar
                                scrollbarColor: "#888 #f1f1f1"
                            }}
                        >
                            {this.state.filteredHostels.map(eachCard => {
                                return (
                                    <div key={eachCard} className="col">
                                        <Card onClick={() => onHostel(eachCard.id)} style={{ cursor: "pointer", borderRadius: "1rem", overflow: "hidden", alignItems: "flex-start", boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px -2px 4px 0px rgba(0, 0, 0, 0.10)", marginTop: "0.5rem" }} className="feature-card mb-3">
                                            <CardBody style={{width:"100%" , padding:"1",display:"flex",alignItems:"start" ,justifyContent:"" }}>
                                                <div className="d-flex" style={{justifyContent:"" , gap:"3rem",width:"100%"}}>
                                                    <div className="search-card-image" style={{ width: "300px", height: "11rem", padding: "0 0px" , width:'30%' }}>
                                                        <img src={eachCard.thumbnail} style={{ borderRadius: "1rem", marginBottom: "4rem", height: "100%", width: "100%", objectFit: "cover" }} alt="HostelCo" />
                                                        <div style={{ borderRadius: "1rem", marginTop: "-6.5rem", width: "max-content", zIndex: "4", backgroundColor: "#251313", opacity: '0.9' }} className="bg-tertiary-o px-2 py-1 ms-2 text-white">Rs. {Math.round(eachCard?.minimumAvgPrice)}</div>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-between" style={{width:"30rem"}}>
                                                        <div>
                                                            <div className="text-black fw-bold" style={{ fontFamily: "Roboto", fontSize: "1.5rem" }} >{eachCard.hostel_name}</div>
                                                            <div className="text-black mb-2" style={{ fontFamily: "Roboto", fontSize: "1rem" }} >{eachCard.location}</div>
                                                            <div className="d-flex gap-2 flex-wrap mb-5">
                                                                {eachCard.amenities.map((eachAmenity, index) => {
                                                                    return (
                                                                        <div className="px-2 py-1 rounded" style={{ backgroundColor: `${uniqueColors[index]}2A`, fontSize: "0.75rem", color: uniqueColors[index], boxShadow: "none" }}>{eachAmenity}</div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-row gap-3 align-items-center justify-content-between">
                                                            <div className="text-white px-3 py-1" style={{ backgroundColor: "#2D7912", borderRadius: "2rem", width: "max-content", boxShadow: "0px 4px 4px 0px #00000040", }}>
                                                                <i className="bi bi-star-fill"></i> {eachCard.ratings || '-'}
                                                            </div>
                                                            <div style={{fontFamily:"Roboto"}} className="text-secondary">
                                                               {`${eachCard.userRatingCount} reviews via Google`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="search-mob-card-cover"
                            style={{
                                gridTemplateColumns: "repeat(2, 1fr)", // 2 columns
                                gap: "10px", 
                                // marginLeft:"-1rem"
                            }}
                        >
                            {this.state.filteredHostels.map((eachCard, index) => (
                                <div key={index} style={{ width: "100%" }}>
                                    <BookCardMobile eachHostel={eachCard} hostelRedirect={onHostel} price={Math.round(eachCard?.minimumAvgPrice)} showReviews={true} />
                                </div>
                            ))}
                        </div>

                        {this.state.filteredHostels.length > 0 && (
                            <div className="col d-flex flex-grow-1">
                                <MyMap mapData={this.state.mapData} mapCenter={this.state.mapCenter} mapZoom={this.state.mapZoom} /> {/* Add the MyMap component here */}
                            </div>
                        )}
                    </div>
                    </>
)}
</>
                </div>
                <div className="bg-dark-o search-page-container">
                    <div className="main-container d-flex gap-3 justify-content-between m-5 flex-wrap">
                        <div>
                            <div className=" h4 text-white my-3 fw-bold">About</div>
                            <div className="text-white mb-5">
                                {this.state.searchText}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white search-page-container">
                    <div className="main-container py-5">
                        <div className="heading-font-2 text-red h2 text-center mb-5">
                            Frequently asked questions
                        </div>
                        <div className="faq-div">
                            <Accordion open={this.state.open} toggle={toggle}>
                                {faqQuestions && faqQuestions.length > 0 ? (
                                    faqQuestions.map((question, index) => (
                                        <AccordionItem key={index} className="mb-3 bg-transparent border-none">
                                            <AccordionHeader
                                                targetId={index + 1}
                                                className="accordion-button-none"
                                            >
                                                <p className="me-3 text-dark">{question}</p>
                                                <i
                                                    className="bi bi-plus-circle-fill position-absolute end-0 ms-5 me-3 fs-1"
                                                    style={{ opacity: 0.5 }}
                                                ></i>
                                            </AccordionHeader>
                                            <AccordionBody
                                                className="text-dark"
                                                accordionId={index + 1}
                                            >
                                                {faqAnswers[index]}
                                            </AccordionBody>
                                        </AccordionItem>
                                    ))
                                ) : (
                                    <div>No FAQs available</div>
                                )}
                            </Accordion>
                            <div className='faq-img-div'>
                                <img src={faq} alt='faq' className='faq-img' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search-page-container">
                    <Footer />
                </div>
            </div>
        )
    }
}

export default withRouter(SearchPage)
