import React, { useRef, useEffect } from "react";
import "bootstrap-daterangepicker/daterangepicker.css"; // DatePicker styles
import $ from "jquery"; // Import jQuery
import "bootstrap-daterangepicker"; // Import the plugin
import moment from "moment"; // Import moment.js

const DateRangePickerMobile = ({ onChange }) => {
  const pickerRef = useRef(null);
  let fromDate;
  let toDate;

  useEffect(() => {
    const options = {
      autoApply: true,
      autoUpdateInput: false,
      alwaysShowCalendars: true, // Keeps the calendar open
      startDate: fromDate ? moment(fromDate, "DD/MM/YYYY") : moment(),
      endDate: toDate ? moment(toDate, "DD/MM/YYYY") : moment(),
      minDate: moment(),
      locale: {
        format: "DD/MM/YYYY",
      },
    };

    $(pickerRef.current).daterangepicker(options, function (start, end) {
      onChange({ fromDate: start.format("DD/MM/YYYY"), toDate: end.format("DD/MM/YYYY") });
      $(pickerRef.current).val(`${start.format("DD/MM/YYYY")} - ${end.format("DD/MM/YYYY")}`);
    });

    // 🔥 Prevent it from closing when clicking outside
    $(pickerRef.current).on("hide.daterangepicker", function (ev, picker) {
      setTimeout(() => {
        pickerRef.current?.click(); // Reopen immediately
      }, 0);
    });

    // Automatically open the date picker
    setTimeout(() => {
      $(pickerRef.current).click();
    }, 200);

    return () => {
      $(pickerRef?.current)?.data("daterangepicker")?.remove();
      $(pickerRef.current).off("hide.daterangepicker"); // Remove event listener
    };
  }, [onChange, fromDate, toDate]);

  return <input type="text" ref={pickerRef}   className="daterangepicker_input desktop-daterangepicker" placeholder="Select Date Range" readOnly />;
};

export default DateRangePickerMobile;
