const BookCardMobile = ({ eachHostel, hostelRedirect,price,showReviews }) => {
    if(!price){
        price = eachHostel.minimumRoomPrice || eachHostel.price;
    }
    return (
        <div
            className="card text-bg-dark position-relative"
            style={{
                borderRadius: "1rem",
                overflow: "hidden",
                minWidth: "12rem",
                width:"12rem",
                height: "11rem",
                fontFamily: "Poppins",
            }}
        >
            <img
                src={eachHostel.thumbnail}
                className="card-img"
                alt="Dormitory Image"
                style={{ objectFit: "cover", height: "100%" }}
            />
            <div onClick={() => { hostelRedirect(eachHostel.id) }}
                className="card-img-overlay d-flex flex-column justify-content-between"
                style={{
                    padding: "0rem",
                }}
            >
             { showReviews &&   <div className="d-flex gap-1 align-items-center px-2 py-1 justify-content-between" style={{fontSize:"0.6rem",background: "linear-gradient(rgba(61,61,61,0.6), rgba(0,0,0,0.6))"}}>
                    <div className="text-white" style={{ padding:"5px 10px",backgroundColor: "#2D7912", borderRadius: "1rem", width: "max-content", boxShadow: "0px 4px 4px 0px #00000040", }}>
                        <i className="bi bi-star-fill"></i> {eachHostel.ratings || '-'}
                    </div>
                    <div> <div style={{ fontFamily: "Roboto" }} className="text-light">
                        {`${eachHostel.userRatingCount} reviews via Google`}
                    </div></div>
                </div>}
                {!showReviews && <div></div>}
            <div style={{background: "linear-gradient(rgba(61,61,61,0.6), rgba(0,0,0,0.6))",padding:"0.5rem",
                padding:"0.1rem 0.5rem 0.5rem 0.5rem",justifySelf:"end"
            }}>
                <div>
                    <div className="d-flex justify-content-between" >
                        <h5 className="card-text text-white fw-semibold"
                            style={{
                                // padding:"0rem 0.3rem",
                                fontSize: "12px",
                                textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
                            }}
                        > {eachHostel.hostel_name} </h5>
                        <h5 className="card-text text-white fw-semibold" style={{
                                padding:"0rem 0.3rem",
                                fontSize: "12px",
                                textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
                            }} > {eachHostel.location} 
                        </h5>
                    </div>
                <div className="d-flex justify-content-between">
                    <div>
                    <p className="card-text text-white fw-medium"
                        style={{
                            padding:"0rem 0.3rem",
                            fontSize: "10px",
                            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
                        }}
                    > Rs.{price}
                    </p>
                    </div>
                    <button className=" bg-transparent fw-bold text-white" 
                    style={{ border: "none" ,fontSize:"10px",textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",}}>
                        Book Now</button>
                </div>
                    </div>

                <div className="d-flex flex-wrap" >
                    {eachHostel.amenities.map((tag, index) => (
                        <div key={index} className="tag text-dark bg-light"
                            style={{ borderRadius: "15px", width: "fit-content", fontSize: "5px", padding: "1px 3px", alignSelf: "center" }} >
                            {tag}
                        </div>
                    ))
                    }
                    
                </div>
                {/* <div className="d-flex justify-content-end ">
                    <button className=" bg-transparent fw-bold text-white" 
                    style={{ border: "none" ,fontSize:"10px",textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",}}>
                        Book Now</button>
                </div> */}
            </div>

            </div>
        </div>
    );
};

export default BookCardMobile