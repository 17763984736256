import React, { useEffect } from 'react';
import "../../src/Pages/styles/MobileDatePickerCalendar.css";
import DateRangePickerMobile from './DatePickerMobile';
const MobileDatePickerCalendar = ({setOpenCalendar ,openCalendar , onChange}) => {
  useEffect(() => {
    if(openCalendar){
        document.body.style.overflow = "hidden";
        document.documentElement.style.overflow = "hidden";
    }else{
        document.body.style.overflow = "";
        document.documentElement.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    };
  }, [openCalendar]);

  return (
    <div className='container'>
      <div className='heading'>
      <i class="bi bi-arrow-left" onClick={()=>setOpenCalendar()}></i>   Select checkIn - checkOut Date
      </div>
      <div className='calendar-div'>
        <DateRangePickerMobile  onChange={onChange}/>
      </div>
    </div>
  );
};

export default MobileDatePickerCalendar;
