import React from "react";
import {
  Button,
  Card,
  CardBody,
  Input,
  Accordion,
  AccordionItem,
  AccordionBody,
  AccordionHeader,
} from "reactstrap";
import "react-select-search/style.css";
import SelectSearch from "react-select-search";
import Discover from "../Assets/DISCOVER2.jpg";
import Footer from "../Components/Footer";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { baseUrl } from "../index";
import "../App.css";
import Slider from "react-slick";
import faq from "../Assets/faq.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SearchBarWithOptions from "../Components/SearchBarWithOptions";
import BookCardMobile from "../Components/BookCardMobile";

//SLIDER IMAGES IMPORT
import Anjuna from "./assets/AnujanFleaMarket.jpg"
import GroupOnMountains from "./assets/imgs/group_on_mountains.png"
import NewImgMobile from "../Assets/new-img-mobile.jpeg"
import img1 from "./assets/imgs/BunkdExperiences.jpg"
import img2 from "./assets/imgs/BunknBrewCompressed.png"
import img3 from "./assets/imgs/Gaia_paintin.jpg"
import img4 from "./assets/imgs/Unearth_Bonfire.jpg"
import img5 from "./assets/imgs/GaiaTrek.jpg"
import img6 from "./assets/imgs/LifeSrtyleBibhitaki.jpg"
import img7 from "./assets/imgs/HostelExp2.jpg"
import img8 from "./assets/imgs/YogaWorkoutJPG.jpg"
import img9 from "./assets/imgs/Roambay.jpg"
import img10 from "./assets/imgs/HOT_Experiences.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component";
import DateRangePicker from "../Components/Datepicker";
import moment from "moment";

const SamplePrevArrow = (props) => {
  const {onClick} = props
  return (
    <div className="custom-arrow prev" onclick={onClick}>
    <span className="arrow-content">❮</span>
  </div>
  );
};

const SampleNextArrow = (props) => {
  const {onClick} = props 
  return (
    <div className="custom-arrow next" onClick={onClick}>
      <span className="arrow-content">❯</span>
    </div>
  );
};




const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 1,
  speed: 500,
  arrows: true,
  indicator: true,
  dots:true,
customPaging: function (i) {
  return (
    <div className="custom-dot">
      <span className="dot"></span>
    </div>
  );
},
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        variableWidth: true,

      },
    },
  ],
};
const smallDeviceSlider = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 1,
  speed: 500,
  arrows: true,
  indicator: true,
  variableWidth: true,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
 row:"1",
  customPaging: function (i) {
  return (
    <div className="custom-dot">
      <span className="dot"></span>
    </div>
  );
},
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};


class LandingPage extends React.Component {
  constructor() {
    super();
    this.state = {
      check:"",
      searchInput: "",
      hostelId:"",
      fromDate: "",
      toDate: "",
      nextDate: "",
      allHostels: [],
      allLocations: [],
      allWords: [],
      allProperties:[],
      otherSearch:[],
      open: "0",
      destImage:0,
     allImages : [
        {
          id: 1,
          title: "Anjuna",
          description: "",
          imageUrl: Anjuna,
          location: "Anjuna",
        },
        {
          id: 2,
          title: "GroupOnMountains",
          description: "",
          imageUrl: GroupOnMountains,
          location: "GroupOnMountains",
        }
      ],
      



 expImages: [
        { id: 1, title: "Beachday@Bunkd", imageUrl: img1, location: "Bunkd", colSize: 6 ,hostelId: 21122402},
        { id: 2, title: "Evening Scenes@Bunknbrew", imageUrl: img2, location: "Mandala", colSize: 6,hostelId: 10022501 },
        { id: 3, title: "Painting@Gaia", imageUrl: img3, location: "Gaia", colSize: 4,hostelId: 20122401 },
        { id: 4, title: "Bonfire@Unearth", imageUrl: img4, location: "Unearth", colSize: 5,hostelId: 14112401 },
        { id: 5, title: "Trekking", imageUrl: img5, location: "Gaia", colSize: 3,hostelId: 20122401 },
        { id: 6, title: "Evening w Bhibhitaki", imageUrl: img6, location: "Bibhitaki", colSize: 0,noneClass:"tab-none",hostelId: 10022503 },
        { id: 7, title: "Scenes w HostelExp", imageUrl: img7, location: "HostelExp2", colSize: 5,hostelId: 21012501 },
        { id: 8, title: "Seance@HOT", imageUrl: img10, location: "House of Travellers Goa", colSize: 3,hostelId:17022502 },
        { id: 9, title: "Monopoly@roambay", imageUrl: img9, location: "roambay", colSize: 4,hostelId: 17122401 },
        { id: 10, title: "Yoga@Dream Catchers", imageUrl: img8, location: "Dream Catchers", colSize: 4,hostelId: 12012501 },
        { id: 11, title: "Yahzee@HappyPanda", imageUrl:"https://i.ibb.co/7tqzYFDs/Yahtzee-Night-Happy-Panda.jpg", location: "Happy-Panda", colSize: 4,hostelId: 13022501 },
        { id: 12, title: "Glitteron@PappiChulo", imageUrl:"https://i.ibb.co/jPp3671Q/Pappi-Chulo.jpg", location: "Pappi-Chulo ", colSize: 4,hostelId: null },
        { id: 13, title: "Pre-Bar Crawl@Namahstay", imageUrl:"https://i.ibb.co/sv4jLQpF/Namahstay.jpg", location: "Namahstay", colSize: 0,hostelId: null },
        { id: 14, title: "DJNight@PappiChulo", imageUrl:"https://i.ibb.co/JFGFCKVQ/Pappi-Chulo-Party.jpg", location: "Pappi-Chulo-Party", colSize: 6,hostelId: null },
        { id: 15, title: "MusicNight@DreamCatchers", imageUrl:"https://i.ibb.co/chDZn0zr/Music-Night-Dream-Catchers.jpg", location: "Dream Catchers", colSize: 6,hostelId: 12012501 },
      ],
  }}
  componentDidMount() {
    Promise.all([
      axios.get(`${baseUrl}/hostel/all-hostels`),
      axios.get(`${baseUrl}/hostel/hostel-location`),
      axios.get(`${baseUrl}/hostel/hostel-words`),
    ])
      .then(([hostelsResponse, locationsResponse, wordsResponse]) => {
        let allHostels = hostelsResponse.data;
        const allLocations = locationsResponse.data;
        const allWords = wordsResponse.data;
        // Combine hostels and locations into allProperties
        const hostelIds = allHostels?.map((r) => r?.id);
    
        axios
          .get(`${baseUrl}/hostel/rooms/minimum-price`, {
            params: { hostelIds: hostelIds.join(",") },
          })
          .then((res) => {
            const minPrice = res.data;
            // Updating hostels with the minimum price data
            let updatedHostels = allHostels?.map((hostel) => {
              const price = minPrice[hostel?.id] || 0;
              return {
                ...hostel,
                minimumRoomPrice: price,
              };
            });
    
            // Combine hostels and locations into allProperties
            const allProperties = [
              ...updatedHostels.map((hostel) => ({
                name: hostel.hostel_name,
                value: hostel.hostel_name,
                type: "hostel",
                hostelid: hostel.id,
              })),
              ...allLocations.map((location) => ({
                name: location.name,
                value: location.name,
                type: "location",
                hostelid: "",
              })),
              ...allWords.map((words) => ({
                name: words.words_Name,
                value: words.words_Name,
                type: "word",
                hostelid: "",
              })),
            ];
            console.log(updatedHostels);
            if(baseUrl !== 'http://localhost:5000'){
            let specificHostels = [
              28022501, // 12 Monks Rishikesh
              10022501, // Bunk n Brew
              16122401, // Ostel In
              17112402, // Nomad House
              11012501, // This is it Beachfront
              7032501, // Pappi Chulo Hostel
              17022503, // Tamasha Goa
              28022502 // The Blue Jay Hostel Rishikesh
            ];
            
            updatedHostels = updatedHostels.filter(hostel => specificHostels.includes(hostel.id));
          }
            this.setState({
              allHostels: updatedHostels,
              allLocations,
              allProperties,
              allWords,
            });
          })
          .catch((err) => {
            console.log("Error fetching min price:", err.message);
          });
      })
      .catch((err) => {
        console.log("Error fetching data:", err.message);
      });
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
    this.setState({ fromDate: nextDay, toDate: dayAfterTomorrow });
  }

  render() {
    const onChange = (e) => {
      const { name, value } = e.target;
      if (name === "fromDate") {
        const nextDay = new Date(value);
        nextDay.setDate(nextDay.getDate() + 1);
        this.setState({ nextDate: nextDay.toISOString().split("T")[0] });
      }
      this.setState({ [name]: value });
    };
    const toggle = (id) => {
      if (this.state.open === id) {
        this.setState({ open: "" });
      } else {
        this.setState({ open: id });
      }
    };

    
    const handleDate =(date) =>{
      this.setState({fromDate:moment(date.fromDate, "DD/MM/YYYY").format("ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)"), toDate:moment(date.toDate, "DD/MM/YYYY").format("ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)"),},()=>{
        console.log(this.state.fromDate)
        console.log(this.state.toDate)
      })
    }

    const locationRedirect = (id)=>{
      const { fromDate, toDate } = this.state
      window.location.href = `/search?city=${id}&from=${new Date(fromDate).toISOString().split("T")[0]}&to=${new Date(toDate).toISOString().split("T")[0]}`;
    }
    const onSubmit = () => {
      const { searchInput, hostelId, fromDate, toDate, otherSearch } = this.state;
      var temp = otherSearch.join(",");
    
      const formatDate = (date) => {
        return new Date(new Date(date).setHours(12, 0, 0, 0)).toISOString().split("T")[0]; 
      };
      const formattedFromDate = formatDate(fromDate);
      const formattedToDate = formatDate(toDate);
    
      if (hostelId.hostelid === "") {
        window.location.href = `/search?city=${searchInput}&from=${formattedFromDate}&to=${formattedToDate}&otherSearch=${temp}`;
      } else {
        window.location.href = `/hostel?hostel=${hostelId.hostelid}&from=${formattedFromDate}&to=${formattedToDate}&otherSearch=${temp}`;
      }
    };
    const hostelRedirect = (id) => {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      window.location.href = `/hostel?hostel=${id}&from=${
        today.toISOString().split("T")[0]
      }&to=${tomorrow.toISOString().split("T")[0]}`;
    };

    const searchDisabled = !(
      this.state.check
    );

    const handleAmenityClick = (amenity) => {
      const today = new Date().toISOString().split("T")[0];
      const dayaftertomrw = new Date(
        new Date().setDate(new Date().getDate() + 1)
      )
        .toISOString()
        .split("T")[0];
      window.location.href = `/search?amenity=${amenity}&from=${today}&to=${dayaftertomrw}`;
    };

    return (
      <div className="whole-page">
      <div className='bg-white flex flex-row gap-0' id="home">

      <div className="hero-sec container-fluid p-0">
      <div className="d-flex flex-row align-items-center">
        <div className="p-5" style={{fontFamily:"Montserrat, Sans-serif",width:"40%",zIndex:'10'}}>
          <div className="d-flex flex-column justify-content-between h-100">
            <h1 className="fw-bold mb-3">
              <span className="text-dark" style={{fontSize:"9.375rem",letterSpacing:'0.25rem'}}>
                <span style={{
                  backgroundColor: '#FD590C',
                  borderRadius: '50%',
                  marginRight:'-1.6rem',
                  padding: '0.1rem 2rem',
                }}> E</span>xplore</span> 
              <br /> <div className="d-block pt-3 " style={{letterSpacing:"0.25rem"}}>THE WONDERS OF THE WORLD</div>
            </h1>
            <div className="fw-medium text-black mb-4" style={{fontSize:"1.5rem"}}>
            Craziest backpacker hostels 
            aren’t just places to crash
            —they’re wild adventures in 
            themselves! From treehouse 
            dorms in the jungles.
            </div>
            
            <div className="search-component-placeholder">
              {/* Your existing search component will go here */}
              
                    <div className='search-bar-main'>
                      <div className='search-bar' >
                        <div className='where' >
                          <div className='fw-bold d-flex gap-3 align-items-center  text-dark px-2 '> <i class="bi bi-geo-alt-fill fs-4"></i>Where</div>
                          <SelectSearch
                            options={this.state.allProperties.sort((a, b) => {
                              // Determine the category for each item (location, hostel, or other)
                              const categoryA = a.type === 'location' ? 1 : a.type === 'hostel' ? 2 : 3;
                              const categoryB = b.type === 'location' ? 1 : b.type === 'hostel' ? 2 : 3;

                              // Sort by category (lower value comes first)
                              return categoryA - categoryB;
                            })}
                            name="searchInput"
                            search
                            onChange={(value, hostelid, type) => {

                              console.log("Selected value:", hostelid,value);
                              this.setState((prevState) => ({
                                searchInput: hostelid.type != "word" ? value : "", // Updates the state with the selected value
                                hostelId: hostelid,
                                check: value,
                                otherSearch: [...[], value], // Updates the array immutably

                              }));

                            }}
                            filterOptions={[(options, query) => {
                              if (query.length < 1) {
                                return []; // Show no options if input is less than 2 characters
                              }
                              return options.filter((option) =>
                                option.name.toLowerCase().startsWith(query.toLowerCase()) // Check if the name starts with the query
                              );
                            }]}
                            placeholder="Where are you going?"
                          // className="custom-select-search"
                          />
                        </div>
                        <div className='check-in'>
                          <div className='fw-bold d-flex gap-3 align-items-center  text-dark px-2   '> <i class="bi bi-calendar-fill fs-5 "></i> Check In - Check Out</div>
                          <DateRangePicker onChange={handleDate} fromDate={this.state.fromDate} toDate={this.state.toDate} />

                        </div>

                        <button
                          disabled={searchDisabled}
                          onClick={onSubmit}
                          className='search-bar-button'
                        >
                          search now
                        </button>
                      </div>
                    </div>

            </div>
          </div>
        </div>
        <div className="p-0">
          <img 
            src= {`${GroupOnMountains}` }
            style={{width:"56.35rem",height:"40rem",}}
            alt="Backpackers exploring mountains" 
            className="img-fluid object-cover"
          />
        </div>
      </div>
    </div>

  <div className="slider-main">
       
          </div>
{/* mobile single image */}
  <div style={{marginTop:"-5rem" ,marginBottom:"2rem"}}  className="mobile-single-img" >
  <div
                  style={{width:"100vw"}}>
                    <a onClick={() => {
                      // console.log(this.state.allImages[0])
                      let location = this.state.allImages[0].title.split(/\s+/);
                      locationRedirect(location[0]);
                    }} style={{ cursor: "pointer" }}>
                     
                  <img style={{borderRadius:"0"}}
                    src={NewImgMobile}
                    alt={`image-${this.state.allImages[0].id}`}
                    className="slider-image"
                  />
                  {/* <div className="text-on-image-slider-div" style={{top:"15%"}}>
                  <h1 className="slider-image-heading">
                  {this.state.allImages[0].title}                  
                  </h1>
                  <p className="slider-image-para" style={{fontSize:"0.5rem"}}>
                  The world is much bigger and people much better then you
                  <br/>
                  give them credit for why would you die without first living.
                  </p>
                  <p  className="shadow"
                  style={{color:"white",fontWeight:"500",textTransform:"initial",margin:"0.5rem 0 0 1.5rem",fontFamily:"Montserrat"}}>
                    Call <span style={{fontSize:"1.2rem",fontWeight:"600"}}>+91 7625083003</span> (manually attended) & 
                    <span style={{paddingLeft:"0.5rem",fontWeight:"600",fontSize:"1.3rem"}}>get 100 off </span> from lowest price 
                    <span style={{paddingLeft:"0.5rem",fontSize:"1.2rem",fontWeight:"600"}}>if we are not lowest priced</span> </p>
                  </div> */}
                  </a>

                </div>
  </div>

{/* MOBILE SEARCH-BAR */}
<SearchBarWithOptions toDate={this.state.toDate}
  fromDate={this.state.fromDate}
  hostelId={this.state.hostelId}
  otherSearch={this.state.otherSearch}
  searchInput={this.state.searchInput}
  onChange={onChange}
  setDates={(from, to) => this.setState({ fromDate: from, toDate: to })}
  allProperties={this.state.allProperties}
  check={this.state.check}
  ></SearchBarWithOptions>




    <div className="exp-container container-fluid text-white py-4" style={{ backgroundColor: "rgb(255 255 255)" }}>
  <div className="row g-2 mb-4" style={{ overflowX: "auto", whiteSpace: "nowrap", scrollbarWidth: "thin",justifyContent:"center" }}>
    {this.state.expImages.slice(0, 6).map((image) => (
      <div key={image.id} className= {`col-${image.colSize} col-md-4 col-lg-2 position-relative`} style={{ overflow: "hidden" ,cursor:"pointer" }}
      onClick={()=>{if(image.hostelId){hostelRedirect(image.hostelId)}}}> 
        <LazyLoadImage 
          src={image.imageUrl} 
          alt={image.title} 
          className={`img-fluid rounded ${image.noneClass || ""}`}
          style={{ width: "100%", height: "7.375rem", objectFit: "cover" }}
        />
        <div 
          className="position-absolute text-white px-1 py-1"
          style={{ 
            bottom: "5px", 
            right: "5px", 
            background: "rgba(0, 0, 0, 0.6)", 
            fontSize: "0.7rem", 
            borderRadius: "5px",
            maxWidth: "90%", 
            whiteSpace: "nowrap", 
            overflow: "hidden", 
            textOverflow: "ellipsis",
          }}
        >
          {image.title}
        </div>
      </div>
    ))}
  </div>

  <div className="mb-4 ">
    <h2 className="exp-head ml-5 h3 mb-2 text-center" style={{ color: "rgba(255, 117, 53, 1)", fontFamily: "Sora", fontWeight: "600" }}>
      Experiences
    </h2>
    <p className="exp-para h4 fw-bolder text-center"
      style={{
        background: "linear-gradient(to bottom,rgba(253, 89, 12, 1),rgba(0, 0, 0, 1))",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontFamily: "Montserrat",
      }}
    >
      Meet travelers,<br />
      adventure, culture,<br />
      and new friendships<br />
      await!
    </p>
    <div className="row g-2 exp-white-cover gap-3" style={{ opacity: "10%", zIndex: "100" }}>
    <div className="row exp-mob-white" style={{marginLeft:"0.3rem"}}>
      {this.state.expImages.slice(2, 5).map((image) => (
        <div key={image.id} className= {`col-${image.colSize} col-md-4 col-lg-2`}>
          <LazyLoadImage 
            src={image.imageUrl} 
            alt={image.title} 
            className="img-fluid rounded"
            style={{ width: "100%", height: "7.375rem", objectFit: "cover" }}
          />
        </div>
      ))}
      </div>
     <div className="row none">
     {this.state.expImages.slice(0,6).map((image) => (
        <div key={image.id} className="col-6 col-md-4 col-lg-2">
          <LazyLoadImage 
            src={image.imageUrl} 
            alt={image.title} 
            className="img-fluid rounded"
            style={{ width: "100%", height: "7.375rem", objectFit: "cover" }}
          />
        </div>
      ))}
     </div>
     <div className="row mob-none" >
     {this.state.expImages.slice(7,13).map((image) => (
        <div key={image.id} className="col-6 col-md-4 col-lg-2">
          <LazyLoadImage 
            src={image.imageUrl} 
            alt={image.title} 
            className="img-fluid rounded"
            style={{ width: "100%", height: "7.375rem", objectFit: "cover" }}
          />
        </div>
      ))}
     </div>
    
    </div>
  </div>

  <div className="row g-2 " style={{justifyContent:"center"}}>
    {this.state.expImages.slice(6, 12).map((image) => (
      <div key={image.id} className={`col-${image.colSize} col-md-4 col-lg-2 position-relative`}style={{ overflow: "hidden",cursor:"pointer" }}
      onClick={()=>{if(image.hostelId){hostelRedirect(image.hostelId)}}} >
        <LazyLoadImage 
          src={image.imageUrl} 
          alt={image.title} 
          className="img-fluid rounded"
          style={{ width: "100%", height: "7.375rem", objectFit: "cover" }}
        />
        <div 
          className="position-absolute text-white px-1 py-1"
          style={{ 
            bottom: "5px", 
            right: "5px", 
            background: "rgba(0, 0, 0, 0.6)", 
            fontSize: "0.7rem", 
            borderRadius: "5px",
            maxWidth: "90%", 
            whiteSpace: "nowrap", 
            overflow: "hidden", 
            textOverflow: "ellipsis",
          }}
        >
          {image.title}
        </div>
      </div>
    ))}
  </div>
</div>


{/*  EXPERIENCES-MOBILE CLOSED */}

{/* locations */}
        <div className='main-container mt-5 mt-5' id="explore">
          <div className=''>
            <h2 className='text-red fw-bold text-uppercase text-center h2 fw-bold heading-font mb-3'>
              Properties
            </h2>
            <div
              style={{
                height: "5px",
                backgroundColor: "#ed452f",
                boxShadow: "0 4px 4px rgba(0, 0, 0, 0.5)",
                width: "100px",
                margin: "0 auto",
              }}
            />
          </div>
        </div>


        {/* Properties  */} 
    <div className="properties-main">
      <div className="property-list">
{/* { this.state.allHostels.slice(5,13).map((eachHostel) =>( */}
{ this.state.allHostels.slice(0,8).map((eachHostel) =>(
    <div
    className="card text-bg-dark position-relative"
    style={{
        borderRadius: "1rem",
        overflow: "hidden",
        minWidth: "12rem",
        width:"15rem",
        height: "14rem",
        fontFamily: "Poppins",
    }}
>
    <LazyLoadImage
        src={eachHostel.thumbnail}
        className="card-img"
        alt="Dormitory Image"
        style={{ objectFit: "cover", height: "100%" }}
    />
    <div onClick={() => { hostelRedirect(eachHostel.id) }}
        className="card-img-overlay d-flex flex-column justify-content-end"
        style={{
            padding: "0rem",
        }}
    >
    <div style={{background: "linear-gradient(rgba(61,61,61,0.6), rgba(0,0,0,0.6))",padding:"0.5rem",
        padding:"0.1rem 0.5rem 0.5rem 0.5rem"
    }}>
        <div>
        <h5 className="card-text text-white fw-semibold"
            style={{
                padding:"0rem 0.3rem",
                fontSize: "1rem",
                textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
            }}
        > {eachHostel.hostel_name} </h5>

        <div className="d-flex justify-content-between">
            <div>
            <p className="card-text text-white fw-medium"
                style={{
                    padding:"0rem 0.3rem",
                    fontSize: "1rem",
                    textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
                }}
            > Rs.{eachHostel.minimumRoomPrice || eachHostel.price}
            </p>
            </div>
            <button className=" bg-transparent fw-bold text-white" 
            style={{ border: "none" ,fontSize:"1rem",textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",}}>
                Book Now</button>
        </div>
            </div>

        <div className="d-flex flex-wrap" >
            {eachHostel.amenities.map((tag, index) => (
                <div key={index} className="tag text-dark bg-light"
                    style={{ borderRadius: "15px", width: "fit-content", fontSize: "10px", padding: "3px 6px", alignSelf: "center" }} >
                    {tag}
                </div>
            ))
            }
            
        </div>
        {/* <div className="d-flex justify-content-end ">
            <button className=" bg-transparent fw-bold text-white" 
            style={{ border: "none" ,fontSize:"10px",textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",}}>
                Book Now</button>
        </div> */}
    </div>

    </div>
</div>
  ))
}
</div>
    </div>
{/* test cards  */}
     
{/* Properties slider for small devices  */}

<div style={{ width: "100%", overflow: "hidden",marginTop:"-3rem" }} className="mob-cards-cover" >
  <div
    className="horizontal-scroll-card-container"
    style={{
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "auto",
      gap: "2rem",
      padding: "0 1.5rem",
      scrollSnapType: "x mandatory",
      scrollbarWidth: "none",
    }}
  >
    {Array.from({ length: Math.ceil(this.state.allHostels.slice(0, 8).length / 2) }).map((_, index) => (
      <div
        key={index}
        className="column-container"
        style={{
          display: "grid",
          gridTemplateRows: "repeat(2, 1fr)", // Two rows
          gap: "2rem",
        }}
      >
        {this.state.allHostels.slice(0, 8).slice(index * 2, index * 2 + 2).map((eachHostel) => (
         <BookCardMobile key={eachHostel.id} eachHostel={eachHostel} hostelRedirect={hostelRedirect} />
        ))}
      </div>
    ))}
  </div>
</div>


        


        {/* testimonials */}
        <div className="testimonials-container">
<div className="testimonials-texts-div">
  <h1 className="testimonials-heading">Listen to What our  <br/> <span style={{color:"#fd590c"}}>  customers say </span></h1>
  <p className="testimonials-subheading">
  Our travelers have stories to tell and memories to share. From unforgettable stays to warm hospitality,<br/> hear how we’ve made their journeys specials. 
  </p>
</div>

<div className="users-reviews-cover">
<div className="users-reviews">
  <div className="single-user-review-orange">
    <h1 className="fs-4" style={{marginTop:"1rem"}}>Kushgra</h1>
    <p style={{fontSize:"16px" ,width:"80%",textAlign:"center" ,fontFamily:"Lato"}}>The platform has lowest prices compared to all the other platform.</p>
  <div style={{display:"flex",gap:"8px" ,fontSize:"12px"}}>
  {
      Array(5).fill(0).map(() =>(
        <i class="bi bi-star-fill"></i>
      ))
    }
  </div>
  <img className="user-review-image"  src="https://i.ibb.co/SwTNG9VJ/Kushu-Comp2.png"/>
  </div>
    <div className="single-user-review">
      <div className="user-review-text-div">

    <h1 className="fs-4" style={{color:"#fd590c"}}>Shaurya</h1>
    <p className="user-review-para">They have a personal customer service which immediately helps vs other platforms where there is no help.</p>

  <div style={{display:"flex",gap:"8px" ,fontSize:"12px"}}>
     { Array(5).fill(0).map(() =>(
        <i class="bi bi-star-fill" style={{color:"#FFBE40"}}></i>
      ))}
       
  </div>
  </div>

  <img className="user-review-image"  src="https://i.ibb.co/jv7zQzVM/Shaurya-Compressed.png"/>
  </div>

  <div className="single-user-review">
  <div className="user-review-text-div">

    <h1 className="fs-4" style={{color:"#fd590c"}}>Rahul G</h1>
    <p className="user-review-para">  The quality of hostels on the platform is really good. No shady hostels.</p>
  <div style={{display:"flex",gap:"8px" ,fontSize:"12px"}}>
    {  Array(5).fill(0).map(() =>(
        <i class="bi bi-star-fill" style={{color:"#FFBE40"}}></i>
      ))}
  </div>
  </div>
  <img className="user-review-image"  src="https://i.ibb.co/84tcpGMr/Rahul-Compressed.png"/>
  </div>

  <div>

  </div>
</div>
</div>
        </div>
        {/* Callin All creators */}
        <div className='bg-dark-o' style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
          <div className='py-5 d-flex flex-column flex-md-row justify-content-evenly align-items-center gap' style={{ width:"100rem"}}>
            <div className='d-flex flex-column justify-content-center banner-w'>
              <div className='h1 mx-4 text-white heading-font fw-bold'>
                Calling All Creators:
              </div>
              <div className='text-red h1 mx-4 heading-font fw-bold'>
                Travel’s On the House
              </div>
              <div className='text-text m-4 mb-3'>
                Have more than 1000 followers on Insta? Our Creators Travel Free
                program offers you a free stay at the best hostels in India. We
                want to collaborate with artists of all types — photographers,
                videographers, artists, musicians, DJs, bloggers, and so on.
              </div>
              <div>
                <Button onClick={()=>{
                  window.open("https://docs.google.com/forms/d/e/1FAIpQLSe-rcq8Xvz4HUDUMCEBewprZeI4v3ej4rTjqk9OIDnY4DDaOg/viewform", "_blank")
                }} className='primary-button m-4'>Learn more</Button>
              </div>
            </div>
            <div className='d-flex justify-content-center banner-w'>
              <div className='d-flex justify-content-center'>
                <img
                  className='hostel-discover-image'
                  src={Discover}
                  alt='discover'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='bg-white'>
          <div className='main-container py-5'>
            <div className='heading-font-2 text-red fw-bold text-uppercase h2 text-center'>
              Frequently asked questions
            </div>
            <div
              className='mt-3 mb-5'
              style={{
                height: "5px",
                backgroundColor: "#ed452f",
                boxShadow: "0 4px 4px rgba(0, 0, 0, 0.5)",
                width: "150px",
                margin: "0px auto",
              }}
            />
            {/* old accordian */}
            {/* <Accordion open={this.state.open} toggle={toggle}>
              <AccordionItem className='my-3 bg-transparent'>
                <AccordionHeader targetId='1' className='accordion-button-none'>
                  <p className='me-3 text-dark'>
                    I am an introvert travelling solo for the first time – how
                    do I ensure my first Hostel Experience is enjoyable and safe
                    ?
                  </p>
                  <i
                    class='bi bi-plus-circle-fill position-absolute end-0 ms-5 me-3 fs-1 '
                    style={{ opacity: 0.5 }}
                  ></i>
                </AccordionHeader>
                <AccordionBody className='text-dark' accordionId='1'>
                  At TheHostelCo, we have handpicked the coolest, most welcoming
                  hostels to give you a safe, fun, and unforgettable experience.
                  Perfect for solo travelers, our hostels are packed with
                  awesome activities and social spaces where you can meet fellow
                  wanderers and make connections that could last a lifetime! So,
                  dive into the Hostel & THC community vibes, join the fun, and
                  always respect personal space. Keep on Hostelling and make the
                  most of every adventure!
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='mb-3 bg-transparent border-none'>
                <AccordionHeader targetId='2' className='accordion-button-none'>
                  <p className='me-3 text-dark'>
                    Where is the HostelCo Network located ?
                  </p>
                  <i
                    class='bi bi-plus-circle-fill position-absolute end-0 ms-5 me-3 fs-1 '
                    style={{ opacity: 0.5 }}
                  ></i>
                </AccordionHeader>
                <AccordionBody className='text-dark' accordionId='2'>
                  TheHostelCo (THC) currently operates a vibrant network of
                  hostels across key backpacker destinations in India, including
                  Goa, Pondicherry, Rishikesh, Dharamshala, Bir, and more. We
                  are also rapidly expanding our presence across Southeast Asia
                  and other regions in India, aiming to offer a unique and
                  enriching experience for travelers in even more exciting
                  locations.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='mb-3 bg-transparent border-none'>
                <AccordionHeader targetId='3' className='accordion-button-none'>
                  <p className='me-3 text-dark'>
                    How do I choose the perfect Hostel for my first solo trip?
                  </p>
                  <i
                    class='bi bi-plus-circle-fill position-absolute end-0 ms-5 me-3 fs-1 '
                    style={{ color: red, opacity: 0.5 }}
                  ></i>
                </AccordionHeader>
                <AccordionBody className='text-dark' accordionId='3'>
                  To find the perfect hostel, begin by selecting your desired
                  destination. If you're flexible about the location, check out
                  the "Experience" halos. You can filter hostels based on
                  activities, amenities, and customer reviews—and voilà! You’ll
                  discover the hostels that are the best fit for your travel
                  style and preferences.
                </AccordionBody>
              </AccordionItem>
            </Accordion> */}

            <div className='faq-div'>
              <Accordion open={this.state.open} toggle={toggle} className='p-3'>
                <AccordionItem className='mb-3 rounded-pill shadow-sm'>
                  <AccordionHeader
                    targetId='1'
                    className='accordion-custom-header accordion-button-none'
                  >
                    <span className='w-100 px-3'>
                    Can I cancel the booking?
                    </span>
                    <i className='bi bi-plus-circle-fill plus-icon'></i>
                  </AccordionHeader>
                  <AccordionBody accordionId='1' className='text-secondary'>
                  As a default you cannot cancel the booking unless in extraneous circumstances - 
                  however if you do face any major issues upto 1 week before please email to palash@thehostelco.com
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem className='mb-3 rounded-pill shadow-sm '>
                  <AccordionHeader
                    targetId='2'
                    className='accordion-custom-header accordion-button-none'
                  >
                    <span className='w-100 px-3'>
                    Anything I should keep in mind?
                    </span>
                    <i className='bi bi-plus-circle-fill plus-icon'></i>
                  </AccordionHeader>
                  <AccordionBody accordionId='2' className='text-secondary'>
                  Hostels are of very different types - Chain Hostels/ Luxury Hostels / Party Hostel /Hippie Hostels etc - 
                  with each having trade offs of Pricing vs Vibe vs Location vs Facilities vs Cleanliness. 
                  Make sure you investigate the hostel thoroughly before making your booking and remember to keep an open mind.

                  </AccordionBody>
                </AccordionItem>

                <AccordionItem className='mb-3 rounded-pill shadow-sm '>
                  <AccordionHeader
                    targetId='3'
                    className='accordion-custom-header accordion-button-none'
                  >
                    <span className='w-100 px-3'>
                    How much Do I need to pay to hold my booking?
                    </span>
                    <i className='bi bi-plus-circle-fill plus-icon'></i>
                  </AccordionHeader>
                  <AccordionBody accordionId='3' className='text-secondary'>
                  Each hostel has its own pre-decided amount that needs to be paid to hold the booking.
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem className='mb-3 rounded-pill shadow-sm'>
                  <AccordionHeader
                    targetId='4'
                    className='accordion-custom-header accordion-button-none'
                  >
                    <span className='w-100 px-3'>
                    If I face any issue what is the resolution?
                    </span>
                    <i className='bi bi-plus-circle-fill plus-icon'></i>
                  </AccordionHeader>
                  <AccordionBody accordionId='4' className='text-secondary'>
                  Please reach out to +91 9673203092 Incase you face any issue during the reservation process or your stay.
                  </AccordionBody>
                </AccordionItem>
              </Accordion>

              <div className='faq-img-div'>
                <img src={faq} alt='faq' className='faq-img' />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      </div>
    );
  }
}

export default LandingPage;