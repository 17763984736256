import React from "react";
import { baseUrl } from "../index";
import axios from "axios";
import { LazyLoadImage} from "react-lazy-load-image-component";

import {
  Button,
  Card, 
  CardBody,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  Alert,
} from "reactstrap";
import Moment from "react-moment";
import randomColor from "randomcolor";
import { connect } from "react-redux";
import { bookedHostel } from "../Redux/booking";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

// Components
import Footer from "../Components/Footer";
import withRouter from "../Components/withRouter";
import BookingPopup from "../Components/BookingModal";

// Styles and assets
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/HostelPage.css";
import HostelRoom from "../Assets/HostelRoom.png";
import typeImg from "./assets/type.png";
import characterImg from "./assets/character.png";
import socialImg from "./assets/social.png";
import diversityImg from "./assets/diversity.png";
import bedImg from "./assets/bed.png";
import dormImg from "./assets/dorm.png";
import catImg from "./assets/cat.png";
import dogImg from "./assets/dog.png";
import checkinImg from "./assets/checkin.png";
import checkoutImg from "./assets/checkout.png";
import alcoholImg from "./assets/alcohol.png";
import smokingImg from "./assets/smoking.png";
import ageImg from "./assets/age.png";
import petsImg from "./assets/pets.png";
import hostelfacilitiesImg from "./assets/hostelfacilities.png";
import "../index.css"
import "../App.css"
import Beach from "../Assets/slider-preview-images/grily.jpg"
import Flying from "../Assets/slider-preview-images/ice.jpeg"
import Trek from "../Assets/slider-preview-images/trek.jpg"
import Friends from "../Assets/slider-preview-images/friends.jpeg"
import ProductImageGallery from "../Components/ProductImageGallery";

const smallDeviceSlider = {
  infinite: true,
  slidesToShow: 3,
  speed: 500,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};
class HostelPage extends React.Component {
  constructor() {
    super();
    this.state = {
      flag:false,
      checkInDate: "",
      checkOutDate: "",
      hostelId: 0,
      days: 0,
      bookingLimit: 0, 
      counter:0,
      hostel: [],
      totalAmount: 0,
      inventory: [],
      bedroomImg:[],
      commonareasImg:[],
      washroomImg:[],
      othersImg:[],
      allImages: [],
      isModal: false,
      isImageModalOpen: false,
      selectedImage: null,
      selectedIndex: 0,
      isPopupOpen:false,
      bookingData: {},
      activePlaceOffersTab: "Vibes",
      isLaptop: window.matchMedia("(min-width: 1024px)").matches, // Check initial screen size
      isMobile: window.matchMedia("(max-width: 768px)").matches,
      experienceImages:[], 
        reviews : [],
        placeholderCount: window.innerWidth < 500 ? 1 : 4,
    };
    this.experiencesRef = React.createRef();
    this.aboutRef = React.createRef();
    this.roomRef = React.createRef();
    this.facilitiesRef = React.createRef();
    this.reviewsRef = React.createRef();
    this.imageSliderRef = React.createRef();
    this.sliderRef = React.createRef();
    this.bedroomRef = React.createRef();
    this.washroomRef = React.createRef();
    this.othersRef = React.createRef();
    this.commonareasRef= React.createRef();
  }
  // async componentDidMount() {
  //   const { searchParams } = this.props.router;
  //   this.setState(
  //     {
  //       hostelId: searchParams.get("hostel"),
  //       checkInDate: searchParams.get("from"),
  //       checkOutDate: searchParams.get("to"),
  //     },
  //     () => {
  //       const d1 = new Date(this.state.checkInDate);
  //       const d2 = new Date(this.state.checkOutDate);
  //       const timeDifference = Math.abs(d2 - d1);
  //       const differenceInDays = Math.ceil(
  //         timeDifference / (1000 * 60 * 60 * 24)
  //       );
  //       this.setState({ days: differenceInDays });
  //     }
  //   );
  //   axios
  //     .get(`${baseUrl}/hostel/hostel/${searchParams.get("hostel")}`)
  //     .then((response) => {
  //       this.setState({ hostel: response.data });
  //     });
  //   axios
  //     .get(
  //       `${baseUrl}/inventory/hostel-inventory/${searchParams.get("hostel")}`
  //     )
  //     .then((response) => {
  //       const fromDate = new Date(searchParams.get("from"));
  //       const toDate = new Date(searchParams.get("to"));
  //       const filteredData = response.data.filter((item) => {
  //         const itemFromDate = new Date(item.fromDate);
  //         return (
  //           itemFromDate < toDate &&
  //           itemFromDate >= fromDate &&
  //           item.availableRooms > item.bookedRooms
  //         );
  //       });
  //       console.log(filteredData)
  //       const groupedData = Array.from(
  //         filteredData.reduce((map, item) => {
  //           const { roomType, cost, availableRooms, bookedRooms, vendorDiscount,...rest } =
  //             item;
  //           if (map.has(roomType)) {
  //             const existing = map.get(roomType);
  //             existing.cost += cost;
  //             existing.vendorDiscount +=vendorDiscount

  //             existing.availableRooms = Math.min(
  //               existing.availableRooms,
  //               availableRooms
  //             );
  //             existing.bookedRooms += bookedRooms;
  //           } else {
  //             map.set(roomType, {
  //               roomType,
  //               cost,
  //               availableRooms,
  //               bookedRooms,
  //               vendorDiscount,
  //               ...rest,
  //             });
  //           }
  //           return map;
  //         }, new Map())
  //       ).map(([_, value]) => value);
  //       this.setState({ inventory: groupedData });
  //     });
  //   axios
  //     .get(
  //       `${baseUrl}/inventory/hostel-images/cloudinary?folder=${searchParams.get(
  //         "hostel"
  //       )}`
  //     )
  //     .then((response) => {
  //       this.setState({ allImages: response.data });
  //     });
  // }
  updatePlaceholderCount = () => {
    this.setState({ placeholderCount: window.innerWidth < 500 ? 1 : 4 });
  };
  async componentDidMount() {
    window.addEventListener("resize", this.updatePlaceholderCount);
    const { params,searchParams } = this.props.router;
    document.addEventListener("keydown", this.handleKeyDown);
    window.history.pushState({ isImageModalOpen: true }, "");
    // Listen for the back button press
    window.addEventListener("popstate", this.handleBackButton);
    let hostelId,checkInDate,checkOutDate;
    try {
      hostelId = params.id ?? searchParams.get("hostel");
      const defaultDates = this.getDefaultDates()
      checkInDate = searchParams.get("from") ?? defaultDates.fromDate;
      checkOutDate = searchParams.get("to") ?? defaultDates.toDate;
      // Set initial state
      this.setState(
        {
          hostelId: hostelId,
          checkInDate: checkInDate ,
          checkOutDate: checkOutDate ,
        },
        () => {
          // console.log(this.state.hostelId)
          const d1 = new Date(this.state.checkInDate);
          const d2 = new Date(this.state.checkOutDate);
          const timeDifference = Math.abs(d2 - d1);
          const differenceInDays = Math.ceil(
            timeDifference / (1000 * 60 * 60 * 24)
          );
          this.setState({ days: differenceInDays });
        }
      );
    const newPath = `/hostel/${hostelId}`;
    const currentUrl = new URL(window.location.href);
    if (currentUrl.pathname !== newPath) {
        window.history.replaceState({}, '', newPath);
    }
  // console.log(this.state.hostelId)
      // Fetch hostel data
      const hostelResponse = await axios.get(
        `${baseUrl}/hostel/hostel/${hostelId}`
      );
      const reviewResponse= await axios.get(
        `${baseUrl}/hostel/reviews/${hostelId}`
      );
      this.setState({reviews: reviewResponse.data},()=>{
        console.log(this.state.reviews)
      });
      this.setState({ hostel: hostelResponse.data });

      // SETTING BEDLIMIT FROM HOSTEL RESPONSE
      if(hostelResponse.data[0]?.BedLimit){
        this.setState({bookingLimit: hostelResponse.data[0].BedLimit|0})
      }
      if(hostelResponse.data[0]?.bedLimit){
        this.setState({bookingLimit: hostelResponse.data[0].bedLimit|0})
      }
      // Fetch inventory data
      const inventoryResponse = await axios.get(
        `${baseUrl}/inventory/hostel-inventory/${hostelId}/${this.state.checkInDate}/${this.state.checkOutDate}`,
      );
      const fromDate = new Date(this.state.checkInDate);
      const toDate = new Date(this.state.checkOutDate);
      let filteredData = inventoryResponse.data.filter((item) => {
        const itemFromDate = new Date(item.fromDate);
        return (
          itemFromDate < toDate &&
          itemFromDate >= fromDate &&
          item.availableRooms > item.bookedRooms
        );
      });
      const experienceImagesRes  =  await axios.get(`${baseUrl}/hostel/experiences/cloudinary?folder=${hostelId}`)
      const bedroomImagesRes= await axios.get(`${baseUrl}/inventory/bedroom/cloudinary?folder=${hostelId}/bedroom`)
      const washroomImagesRes= await axios.get(`${baseUrl}/inventory/bedroom/cloudinary?folder=${hostelId}/washroom`)
      const commonareasImagesRes= await axios.get(`${baseUrl}/inventory/bedroom/cloudinary?folder=${hostelId}/commonareas`)
      const othersImagesRes= await axios.get(`${baseUrl}/inventory/bedroom/cloudinary?folder=${hostelId}/others`)
      this.setState({bedroomImg:bedroomImagesRes.data,washroomImg:washroomImagesRes.data,commonareasImg:commonareasImagesRes.data,othersImg:othersImagesRes.data,experienceImages:experienceImagesRes.data,allImages:[...commonareasImagesRes.data,...bedroomImagesRes.data,...washroomImagesRes.data,...othersImagesRes.data]});
//filtering rooms on the basis of availability in date range  on rom type level 
      const filterRooms = () => {
        let roomTypeMap = new Map();
        // counting same roomtype of all days 
        for(let i = 0 ;i<filteredData.length;i++){
          const item = filteredData[i]
          const roomType = item.roomType;
          if (roomTypeMap.has(roomType)) {
            roomTypeMap.set(roomType, roomTypeMap.get(roomType) + 1);
          } else {
            roomTypeMap.set(roomType, 1); 
          }
        }
        // filtering.. if roomtype counting is less than selected date difference then removing all rooms of that type 
       const filterType =  filteredData.filter((item) =>{
          const roomType = item.roomType
          const availableForDays = roomTypeMap.get(roomType)
        return  availableForDays >= this.state.days;
        })
      return  filteredData = filterType 
        };
filterRooms()
console.log(filteredData)
      const groupedData = Array.from(
        filteredData.reduce((map, item) => {
          const { roomType, cost, availableRooms, bookedRooms, vendorDiscount,  discountPercentage  ,thcDiscount,...rest } =
            item;
            let  minRooms =  availableRooms - bookedRooms
            console.log( vendorDiscount ,roomType ,cost ,cost * (discountPercentage/100))
          if (map.has(roomType)) {
            const existing = map.get(roomType);
            existing.cost += cost;
            existing.thcDiscount += thcDiscount;
            existing.vendorDiscount = existing.vendorDiscount +  (cost * (discountPercentage/100));
            existing.discountPercentage = discountPercentage;
            existing.availableRooms = Math.min(existing.availableRooms, availableRooms);
            existing.bookedRooms += bookedRooms;
            existing.minRooms = Math.min(existing.minRooms , minRooms)
          } else {
            map.set(roomType, {
              roomType,
              cost,
              availableRooms,
              bookedRooms,
              discountPercentage,
              minRooms,
              thcDiscount,
            vendorDiscount :(cost * (discountPercentage/100)),
              ...rest,
            });
          }
          return map;
        }, new Map())
      ).map(([_, value]) => value);
      this.setState({ inventory: groupedData });
      console.log(this.state.inventory)
      // console.log(this.state.hostel)
    } catch (error) {
      console.error("Error loading data:", error);
      // Optionally, you could show a message to the user indicating the error
      this.setState({ error: "Failed to load some data. Please try again later." });
    }
    this.mediaQuery = window.matchMedia("(min-width: 1024px)");
    this.mediaQuery.addEventListener("change", this.handleResize);
    this.mediaQueryList = window.matchMedia("(max-width: 768px)");
    this.mediaQueryList.addEventListener("change", this.updateScreenSize);
    setTimeout(() => {
      if (this.sliderRef.current) {
        console.log("Forcing slide to 0");
        this.sliderRef?.current.slickGoTo(0);
      } else {
        console.error("Slider ref not found!");
      }
    }, 500); 
    if(this.state.isMobile)
      {
        setTimeout(() => {
          const element = document.getElementsByClassName("custom-image next");
          if (this.state.flag==false && element.length>0) {
            element[0].click(); // ✅ Trigger Click
            this.setState({flag:true});
            console.log("Button clicked programmatically");
          } else {
            console.error("Element not found!");
          }
        }, 0.1);
      }
  }
  

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePlaceholderCount);
    // Remove event listener when the modal is closed
    window.removeEventListener("popstate", this.handleBackButton);
  }
  getDefaultDates = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);  
    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
    // Format dates as YYYY-MM-DD
    const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    };
    // console.log({date:{tomorrow,dayAfterTomorrow}});
    return {
        fromDate: formatDate(tomorrow),
        toDate: formatDate(dayAfterTomorrow)
    };}
  handleScrollToExperiences = () => {
    this.experiencesRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  handleScrollToAbout = () => {
    this.aboutRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  handleScrollToRooms = () => {
    this.roomRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  handleScrollToFacilities = () => {
    this.setState({ activePlaceOffersTab: "Facilities" });
    this.facilitiesRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  handleScrollReviews = () => {
    this.reviewsRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  handleScrollToBedroom = () => {
    this.bedroomRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  handleScrollToCommonAreas = () => {
    this.commonareasRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  handleScrollToWashroom = () => {
    this.washroomRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  handleScrollToOthers = () => {
    this.othersRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  generateUniqueColors = (count) => {
    const colors = new Set();
    while (colors.size < count) {
      colors.add(
        randomColor({
          luminosity: "dark"
        })
      );
    }
    return Array.from(colors);
  };
  
  toggleModal = () => {
    this.setState((prevState) => ({ isPopupOpen: !prevState.isPopupOpen }));
  };

  // Function to update booking data
  handleBookingChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      bookingData: { ...prevState.bookingData, [name]: value },
    }));
    console.log(this.state.inventory)
  };

  handleImageClick = (img, index) => {
    const allImages = [
      ...this.state.commonareasImg,
      ...this.state.bedroomImg,
      ...this.state.washroomImg,
      ...this.state.othersImg,
    ];

    this.setState({
      selectedImage: img,
      selectedIndex: index,
      allImages,
      isImageModalOpen: true,
    });
  };
  handleResize = (event) => {
    this.setState({ isLaptop: event.matches });
  };

  updateScreenSize = (event) => {
      this.setState({ isMobile: event.matches });
    };
  handleNext = () => {
    this.setState((prevState) => {
      const { selectedIndex, allImages } = prevState;
      const newIndex = (selectedIndex + 1) % allImages.length;
      return {
        selectedIndex: newIndex,
        selectedImage: allImages[newIndex],
      };
    });
  };

  handlePrev = () => {
    this.setState((prevState) => {
      const { selectedIndex, allImages } = prevState;
      const newIndex =
        (selectedIndex - 1 + allImages.length) % allImages.length;
      return {
        selectedIndex: newIndex,
        selectedImage: allImages[newIndex],
      };
    });
  };

  closeImageModal = () => {
    this.setState({ isImageModalOpen: false, selectedImage: null });
  };

  handleKeyDown = (event) => {
    if (this.state.isImageModalOpen) {
      if (event.key === "ArrowRight") {
        this.handleNext();
      } else if (event.key === "ArrowLeft") {
        this.handlePrev();
      }
    }
  };
  handleBackButton = () => {
    this.closeImageModal(); // Call the function to close the modal
  };
  renderImageGrid = (images, categoryRef, category) => {
    return (   
      <div ref={categoryRef} style={{ width: this.state.isLaptop ? "1130px" : "100vw",margin: this.state.isLaptop ? "auto" : "0",padding: this.state.isLaptop ? "auto" : "0",...(this.state.isLaptop ? {} : { textAlign: "center" }) }}>
        <h3 style={{margin:"20px 0"}}>{category}</h3>
              <div 
        style={{ 
          ...(this.state.isLaptop ? {} : {  // Apply only if NOT a laptop
            width:"100vw",
            margin:"0",
            padding:"0",
            display: "flex", 
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px"
          })
        }}
      >
        {images.map((img, index) => (
          <img
            key={index}
            className={this.state.isLaptop ? "m-2" : ""} 
            style={{
              maxHeight: "17rem",
              objectFit: "cover",
              width: this.state.isLaptop ? "230px" : "100vw",
              height: this.state.isLaptop ? "160px" : "auto",
              margin: this.state.isLaptop? "auto":"0",
              cursor: "pointer",
            }}
            src={img}
            alt={category}
            onClick={() => this.handleImageClick(img, index)}
          />
        ))}
      </div>
       </div>
    );
  };

  render() {

    const { hostelId  , placeholderCount} = this.state;
    const onChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };
    const onReserve = () => {
      const { checkInDate, checkOutDate, totalAmount, bookingData, hostelId } =
        this.state;
      localStorage.setItem(
        "bookingData",
        JSON.stringify({
          totalAmount :parseFloat(totalAmount).toFixed(2),
          bookingData,
          checkInDate,
          checkOutDate,
          hostelId,
          comissionRateAbs:this.state.hostel[0]?.comissionRateAbs || 0,
        })
      );
      this.props.bookedHostel({
        totalAmount,  
        bookingData,
      });
      window.location.href = `/booking?from=${checkInDate}&to=${checkOutDate}`;
    };
    const settings  = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: false,
      arrows: true,
      indicator: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            infinite: true,
          },
        },
      ],
    };
    const roomAdded = (inventory) => {
      console.log("inventory",inventory)
      const { roomType, cost  ,vendorDiscount ,discountPercentage ,thcDiscount} = inventory;
      const { bookingData, days,bookingLimit,counter  } = this.state;
      let roomData = bookingData;
      if (!roomData[roomType] && (counter < bookingLimit || bookingLimit===0)) {
        roomData[roomType] = { totalRooms: 1, cost ,vendorDiscount ,thcDiscount};
        this.setState({
          bookingData: roomData,
          counter: counter + 1, 
        });
      } else if(roomData[roomType] && roomData[roomType].totalRooms < inventory.minRooms &&  (counter < bookingLimit || bookingLimit===0) ){ 
        roomData[roomType].totalRooms += 1;
        roomData[roomType].vendorDiscount +=vendorDiscount; 
        roomData[roomType].thcDiscount +=thcDiscount; 
        this.setState({
          bookingData: roomData,
          counter: counter + 1,  
        }); 
      } 
      console.log("room data",roomData)
      const totalAmount = Object.values(roomData).reduce((sum, room) => {
        return sum +(( room.totalRooms * room.cost - room.vendorDiscount) * 1.12) - (room.thcDiscount);
      }, 0);
      this.setState({
        bookingData: roomData, 
        totalAmount: totalAmount,
      });
    };
 
    const roomRemoved = (inventory) => {
      const { roomType , vendorDiscount ,discountPercentage ,cost ,thcDiscount} = inventory;
      const { bookingData, days, counter  } = this.state;
      let roomData = bookingData;
      if (!roomData[roomType]) {
        return;
      } else {
        roomData[roomType].totalRooms -= 1;
        roomData[roomType].vendorDiscount -= vendorDiscount
        roomData[roomType].thcDiscount -=thcDiscount; 
        if (roomData[roomType].totalRooms === 0) delete roomData[roomType];
        if(counter>0){
          this.setState({counter:counter-1})
        }
      }
      const totalAmount = Object.values(roomData).reduce((sum, room) => {
        return sum +( (room.totalRooms * room.cost  - room.vendorDiscount) * 1.12)  - (room.thcDiscount);
      }, 0);
      this.setState({
        bookingData: roomData,
        totalAmount: totalAmount,
      });
    };


    const goToPrev = () => {
      this.sliderRef?.current.slickPrev();
    };
  
    const goToNext = () => {
      console.log("next",this.sliderRef?.current)
      this.sliderRef?.current.slickNext();
    };
    const goToNextImage = () => {
      console.log("next",this.sliderRef?.current)
      this.imageSliderRef?.current.slickNext();
    };

    const goToPrevImage = () => {
      this.imageSliderRef?.current.slickPrev();
    };
    const amenities = this.state.hostel[0]?.amenities || [];
    const uniqueColors = this.generateUniqueColors(amenities.length);
    console.log("hostelId",this.state.hostelId)
    return (
    
      <div className="whole-page" id="hostel-container">
        <Helmet>
          <title>
            {this.state.hostel.length > 0
              ? `${this.state.hostel[0].hostel_name} | Hostel in ${this.state.hostel[0].location} - TheHostelCo`
              : "Top Hostels | TheHostelCo"}
          </title>
          <meta
            name="description"
            content={this.state.hostel.length > 0
              ? this.state.hostel[0].description
              : "Discover the best hostels at TheHostelCo. Book your stay today!"}
          />
          <link rel="canonical" href={`https://thehostelco.com/hostel/${this.state.hostelId}`} />
        </Helmet>

        <div className="d-none d-md-block main-container pt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div
              style={{
                background: "linear-gradient(90deg, #DC7C4C 0%, #844A2E 73.9%)",
                WebkitBackgroundClip: "text", // For Safari
                backgroundClip: "text",
                color: "transparent",
              }}
              className="h2 fw-bold mb-0"
            >
              {this.state.hostel[0]?.hostel_name}
            </div>
            <div className="d-flex gap-2 align-items-center">
            <div
              className="text-white px-3 py-1"
              style={{
                backgroundColor: "#2D7912",
                borderRadius: "2rem",
                width: "max-content",
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
            >
              <i className="bi bi-star-fill"></i> { this.state.reviews[0]?.rating || '-'}
            </div>
            <div style={{ fontFamily: "Roboto" }} className="text-secondary">
              {`${this.state.reviews[0]?.userRatingCount || 0 } reviews via Google`}
            </div>
            </div>
          </div>
          <div id="hostel-facility-btn-cover" className="d-flex gap-3" >
            {this.state.hostel[0]?.amenities.map((eachAmenity,index) => {
              return (
                <div
                  className="text-white px-3 py-1"
                  style={{
                    backgroundColor: uniqueColors[index],
                    borderRadius: "0.5rem",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  {eachAmenity}
                </div>
              );
            })}
          </div>
        </div>
     
        <div className="image-slider mt-lg-3">
        <div className="inner-image-slider">
        {
            this.state.allImages.length <= 0 && placeholderCount == 1 && <div className="image-loader-div">{Array.from({length:placeholderCount}).map((_,index) =>(
              <div key={index} className="image-placeholder-mobile animate-shimmer"  /> 
            ))} </div>
          }
          <Slider {...settings} ref={this.imageSliderRef}>
            {this.state.allImages.map((eachImage, index) => {
              return (
                <div
                  key={index}
                  onClick={() => this.setState({ isModal: true })}
                >
                  <LazyLoadImage
                    alt={`image-${index}`}
                    src={eachImage}
                    effect="blur"
                    width="480px"
                    height="450px"
                    style={{ objectFit: "cover", cursor:"pointer" }}
/>
                </div>
              );
            })}
          </Slider>
  <div className="custom-image next " onClick={goToNextImage} style={{ display: "none" }}>
       <i   style={{fontSize:"25px",color:'white'}} class="bi bi-caret-right-fill"></i>
    </div>

    <div className="custom-image prev" onClick={goToPrevImage} style={{ display: "none" }}>
       <i  style={{fontSize:"25px",color:'white'}} class="bi bi-caret-left-fill"></i>
  </div>
          <div
              style={{
                WebkitBackgroundClip: "text", // For Safari
                backgroundClip: "text",
                
              }}
              className="h2 mb-0 d-block d-md-none"
              id="hostelname-mobile"
            >
              {this.state.hostel[0]?.hostel_name}
            </div>
        </div>
        </div>
{/* Image skeleton for large screens */}
        {
          this.state.allImages.length <= 0 && placeholderCount>1 && (
            <div className="image-loader-div">
              
                <div className="product-gallery">
                  <div className="main-image">
                    <div className="image-placeholder animate-shimmer" />
                  </div>
                  <div className="thumbnail-grid">
                    {Array.from({ length: 4 }).map((_, index) => (
                      <div key={index} className="thumbnail">
                        <div className="image-placeholder animate-shimmer" />
                      </div>
                    ))}
                  </div>
                </div>
              
            </div>
          )
        }
        {this.state.allImages.length>0 &&
          <ProductImageGallery images={this.state.allImages} onClick={() => this.setState({ isModal: true })}/> 
          }

        <div className="mt-5 nav-below-cont">
        <div className="px-4">
        <div id="hostel-facility-btn-cover" className="d-flex flex-wrap d-md-none gap-3" >
            {this.state.hostel[0]?.amenities.map((eachAmenity,index) => {
              return (
                <div
                  className="text-white px-2 py-1"
                  style={{
                    backgroundColor: uniqueColors[index],
                    borderRadius: "0.5rem",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  {eachAmenity}
                </div>
              );
            })}
          </div>
        </div>
         
          {/* d-flex gap-3 justify-content-between mb-5 */}
          <div className=" d-flex gap-3 justify-content-between mb-5 about-book">
            <div id="about-des" style={{ maxWidth: '850px'}}>
              <h2 className=" h4 text-black mb-3 fw-bold">About</h2>
              <p className="text-black">{this.state.hostel[0]?.description}</p>

              {/* While you’re here say Hi! To */}
              {this.state.hostel?.[0]?.OtherFacilities?.petName && (
                <div className="pets">
                  <h4
                    className="h4 text-black fw-bold"
                    style={{ margin: "0 40px 0 0" }}
                  >
                    While you’re here say Hi! To
                  </h4>
                  <div className="d-flex">
                    <div className="pet-div">
                      {this.state.hostel?.[0]?.OtherFacilities?.petName?.cat?.map(
                        (c) => (
                          <div key={c} className="pet-btn">
                            <img src={catImg} alt="cat" />
                            <span>{c}</span>
                          </div>
                        )
                      )}
                      {this.state.hostel?.[0]?.OtherFacilities?.petName?.dog?.map(
                        (d) => (
                          <div key={d} className="pet-btn">
                            <img src={dogImg} alt="dog" />
                            <span>{d}</span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.isPopupOpen && (
        <BookingPopup
        isOpen={this.state.isPopupOpen}
        toggle={this.toggleModal}
        checkInDate={this.state.checkInDate}
        checkOutDate={this.state.checkOutDate}
        hostelId={this.state.hostelId}
        days={this.state.days}
        hostel={this.state.hostel}
        totalAmount={this.state.totalAmount}
        inventory={this.state.inventory}
        allImages={this.state.allImages}
        bookingData={this.state.bookingData}
        activePlaceOffersTab={this.state.activePlaceOffersTab}
        experienceImages={this.state.experienceImages}
        onReserve={onReserve}
        onChange={this.handleBookingChange}
        />
      )}
        {/* When you should  go there & not */}
        <div className=" pros-cons-container">
          {this.state.hostel?.[0]?.OtherFacilities?.pros && (
            <div className="pros-cons pros">
              <h4> Why you should go there</h4>
                    <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                      {this.state.hostel?.[0]?.OtherFacilities?.pros?.map((pro) => (
                        <li key={pro} style={{ display: "flex", alignItems: "flex-start", gap: "0.5rem" }}>
                          <i className="bi bi-check-circle-fill" style={{ fontSize: "1.2rem", color: "green", alignSelf: "flex-start" }}></i>
                          <span>{pro}</span>
                        </li>
                      ))}
                    </ul>
              </div>            
          )}
          {this.state.hostel?.[0]?.OtherFacilities?.cons && (
            <div className="pros-cons cons">
              <h4> Why you should not go there</h4>
                    <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                      {this.state.hostel?.[0]?.OtherFacilities?.cons?.map((con) => (
                        <li key={con} style={{ display: "flex", alignItems: "flex-start", gap: "0.5rem" }}>
                          <i className="bi bi-x-circle-fill" style={{ fontSize: "1.2rem", color: "red", alignSelf: "flex-start" }}></i>
                          <span>{con}</span>
                        </li>
                      ))}
                    </ul>              
            </div>
          )}
        </div>
            </div>
            {/* Booking summary */}
            <div className="d-none d-md-flex gap-3 mb-3 justify-content-between flex-lg-row flex-column">
              <div>
                <Card style={{ borderRadius: "1.3rem" }}>
                  <CardBody>
                    <div className="fw-bold h4 text-dark mb-0">
                      Booking summary
                    </div>
                    <div className="fw-bold mb-3" style={{ color: "#ED452F" }}>
                      {this.state.days}{" "}
                      {this.state.days === 1 ? "Night" : "Nights"} starting from{" "}
                      <span className="me-1"></span>
                      <Moment format="DD MMM YYYY">
                        {this.state.checkInDate}
                      </Moment>
                    </div>
                    <div className="row row-cols-lg-2 row-cols-1 g-3 mb-3">
                      <div className="col">
                        <Label className="fw-bold">Check In</Label>
                        <Input
                          style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
                          placeholder="Check In Date"
                          type="date"
                          disabled
                          name="checkInDate"
                          onChange={onChange}
                          value={this.state.checkInDate}
                        />
                      </div>
                      <div className="col">
                        <Label className="fw-bold">Check Out</Label>
                        <Input
                          style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
                          placeholder="Check Out Date"
                          type="date"
                          disabled
                          name="checkOutDate"
                          onChange={onChange}
                          value={this.state.checkOutDate}
                        />
                      </div>
                    </div>
                    {Object.keys(this.state.bookingData).length !== 0 && (
                      <div>
                        {Object.keys(this.state.bookingData).map(
                          (roomType, index) => {
                            return (
                              <div key={index}>
                                <div className="fw-bold text-dark-o mb-2">
                                  {roomType}
                                </div>
                                <div className="d-flex justify-content-between mb-1">
                                  <div>
                                    <i className="bi bi-currency-rupee"></i>
                                    {this.state.bookingData[roomType].cost}{" "}
                                    <i className="bi bi-x"></i>
                                    {
                                      this.state.bookingData[roomType]
                                        .totalRooms
                                    }{" "}
                                  </div>
                                  <div className="fw-bold">
                                    <i className="bi bi-currency-rupee"></i>
                                    {this.state.bookingData[roomType].cost *
                                      this.state.bookingData[roomType]
                                        .totalRooms}
                                  </div>
                                </div>


                                <div className="d-flex justify-content-between mb-1">
                                  <div>Discount</div>
                                  <div className="fw-bold">
                                    <i className="bi bi-currency-rupee"></i>
                                    {(
                                      this.state.bookingData[roomType].vendorDiscount 
                                   
                                    )?.toFixed(2)}
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mb-1">
                                  <div>Tax.</div>
                                  <div className="fw-bold">
                                    <i className="bi bi-currency-rupee"></i>
                                    {(
                                      (this.state.bookingData[roomType].cost *
                                      this.state.bookingData[roomType]
                                        .totalRooms - this.state.bookingData[roomType].vendorDiscount ) *
                                      0.12
                                    )?.toFixed(2)}
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mb-1">
                                  <div>Additional discount</div>
                                  <div className="fw-bold">
                                    <i className="bi bi-currency-rupee"></i>
                                    { this.state.bookingData[roomType].thcDiscount}
                                  </div>
                                </div>
                                {/* <div className="d-flex justify-content-between mb-1">
                                                                <div className="fw-bold">Total</div>
                                                                <div className="fw-bold">
                                                                    <i className="bi bi-currency-rupee"></i>
                                                                    {parseInt(this.state.bookingData[roomType].cost * this.state.bookingData[roomType].totalRooms * this.state.days * 1.1)}
                                                                </div>
                                                            </div> */}
                                <hr className="hr mb-3" />
                              </div>
                            );
                          }
                        )}
                        <div className="d-flex justify-content-between mb-3">
                          <div className="fw-bold">Total</div>
                          <div className="fw-bold">
                            <i className="bi bi-currency-rupee"></i>
                            {this.state.totalAmount.toFixed(2)}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mb-3">
                          <div className="fw-bold">Payable Now</div>
                          <div className="fw-bold">
                            <i className="bi bi-currency-rupee"></i>
                            {(
                              this.state.totalAmount *
                              (this.state.hostel[0]?.payable_percent / 100)
                            ).toFixed(2)}
                          </div>
                        </div>
                        <Button
                          style={{ borderRadius: "0.5rem" }}
                          onClick={onReserve}
                          className="w-100 mb-3 bg-dark-o"
                        >
                          Book Now
                        </Button>
                        <Alert size="sm" color="info">
                          You can apply coupons while checkout.
                        </Alert>
                      </div>
                    )}
                    {Object.keys(this.state.bookingData).length === 0 && (
                      <a
                        style={{
                          borderRadius: "0.5rem",
                          boxShadow: "0px 4px 4px 0px #00000040",
                        }}
                        onClick={()=>{this.handleScrollToRooms()}}
                        className="btn w-100 mb-3 bg-dark-o text-white"
                      >
                        Select room
                      </a>
                    )}
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>

        {/* Experinces images */}
        {this.state.experienceImages.length>0 && 
        <div className="experience-div">
<div className="experience-inner-div">
  <div className="exp-main-left-div"> 
<div className="dest-text-div">
  <h1 className="experience-left-div-heading">
Experiences
  </h1>
  <h2 style={{color:"black"}} className="dest-left-div-sub-heading">
  Glimpses from our Archives/Memories (and our IG)
  </h2>
  </div>
</div>
<div className="exp-images-div" ref={this.experiencesRef}>
                <div
              className="exp-image-inner-div" >
                    {/* first image */}
              {this.state.experienceImages[0] &&     <div style={{color:'white' ,position:"relative",zIndex:100}}>
                <LazyLoadImage
                  src={this.state.experienceImages[0]?.url}
                  alt={`image-${this.state.experienceImages[0]?.title}`}
                  className="exp-slider-left"
                />
                <h1 className="exp-image-heading-sides">{this.state.experienceImages[0]?.title} </h1>
              </div>
              }
                    {/* second image */}
                 {this.state.experienceImages[1] &&  <div style={{color:'white' ,position:"relative",zIndex:100}}>
                  <img
                    src={this.state.experienceImages[1]?.url}
                    alt={`image-${this.state.experienceImages[1]?.title}`}
                    className="exp-slider-center-image"
                  />
                 <h1 className="dest-image-heading-centre">{this.state.experienceImages[1]?.title} </h1>
                    </div>
                 }
{/* third image  */}
                   {this.state.experienceImages[2] &&  <div style={{color:'white' ,position:"relative",zIndex:100}}>
                    <img
                      src={this.state.experienceImages[2]?.url}
                      alt={`image-${this.state.experienceImages[2]?.title}`}
                      className="exp-slider-left"
                    />
                            <h1 className="exp-image-heading-sides">{this.state.experienceImages[2]?.title}  </h1>
                  </div>
                   }

             {  this.state.experienceImages[3] &&
              <div style={{color:'white' ,position:"relative",zIndex:100}}>
              <img
                src={this.state.experienceImages[3]?.url}
                alt={`image-${this.state.experienceImages[3]?.title}`}
                className="exp-slider-center-image"
              />
             <h1 className="dest-image-heading-centre">{this.state.experienceImages[3]?.title} </h1>
                </div>
             }

                 </div>


</div>
</div>
</div>
}
        {/*  What this place offers */}
        <div className="table-main-container">
          <h2 className="offers-heading">
            What to <span style={{color:"#fd590c"}}>  Expect </span>
          </h2>
          <div className="offer-heading-container">
            {this.state.hostel?.[0]?.type?.length > 0 && (
              <h3
                className={`place-offers ${
                  this.state.activePlaceOffersTab === "Vibes" && "active"
                }`}
                onClick={() => this.setState({ activePlaceOffersTab: "Vibes" })}
              >
                Vibes
              </h3>
            )}
            {this.state.hostel?.[0]?.OtherFacilities?.BedFacilities?.length >
              0 && (
              <div ref={this.facilitiesRef}>
              <h3
                className={`place-offers ${
                  this.state.activePlaceOffersTab === "Facilities" && "active"
                }`}
                onClick={() =>
                  this.setState({ activePlaceOffersTab: "Facilities" })
                }
              >
                Facilities
              </h3>
              </div>
            )}
            {this.state.hostel?.[0]?.OtherFacilities?.Rules &&
  Object.keys(this.state.hostel?.[0]?.OtherFacilities?.Rules).length > 0 && (
              <h3
                className={`place-offers ${
                  this.state.activePlaceOffersTab === "Rules" && "active"
                }`}
                onClick={() => this.setState({ activePlaceOffersTab: "Rules" })}
              >
                Rules
              </h3>
            )}
          </div>
          <div>
          {this.state.activePlaceOffersTab === "Vibes" && (
            <div className="offer-container ">
              <hr className="vertical-hr" />
              <>
                {this.state.hostel?.[0]?.type && (
                  <div className="offers">
                    <div className="offer-heading">
                      <img
                        src={typeImg}
                        alt="type"
                        className="offer-heading-img"
                      />
                      <h3 style={{color:"#fd590c"}} >Type</h3>
                    </div>

                    <div className="offerings">
                      {this.state.hostel?.[0]?.type.map((btn) => (
                        <button button className="offerings-button">
                          {btn}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
                <hr className="hr" />
              </>
              <>
                {this.state.hostel?.[0]?.Character && (
                  <div className="offers">
                    <div className="offer-heading">
                      <img
                        src={characterImg}
                        alt="character"
                        className="offer-heading-img"
                      />
                      <h3 style={{color:"#fd590c"}} >Character</h3>
                    </div>
                    <div className="offerings">
                      {this.state.hostel?.[0]?.Character.map((btn) => (
                        <button button className="offerings-button">
                          {btn}
                        </button>
                      ))}
                    </div>
                  </div>
                )}{" "}
                <hr className="hr" />
              </>
              <>
                {this.state.hostel?.[0]?.Social && (
                  <div className="offers">
                    <div className="offer-heading">
                      <img
                        src={socialImg}
                        alt="social"
                        className="offer-heading-img"
                      />
                      <h3 style={{color:"#fd590c"}}>Social</h3>
                    </div>
                    <div className="offerings">
                      {this.state.hostel?.[0]?.Social.map((btn) => (
                        <button button className="offerings-button">
                          {btn}
                        </button>
                      ))}
                    </div>
                  </div>
                )}{" "}
                <hr className="hr" />
              </>
              <>
                {this.state.hostel?.[0]?.Diversity && (
                  <div className="offers">
                    <div className="offer-heading">
                      <img
                        src={diversityImg}
                        alt="diversity"
                        className="offer-heading-img"
                      />
                      <h3 style={{color:"#fd590c"}} >Diversity</h3>
                    </div>

                    <div className="offerings">
                      {this.state.hostel?.[0]?.Diversity.map((btn) => (
                        <button button className="offerings-button">
                          {btn}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </>
            </div>
          )}
          {this.state.activePlaceOffersTab === "Facilities" && (
            <div className="offer-container">   
              <hr className="vertical-hr" />
              <>
                {this.state.hostel?.[0]?.OtherFacilities?.HostelFacilities && (
                  <div className="offers">
                    <div className="offer-heading">
                      <img
                        src={hostelfacilitiesImg}
                        alt="hostel_facilities"
                        className="offer-heading-img"

                      />
                      <h3 style={{color:"#fd590c"}}>Hostel Facilities</h3>
                    </div>

                    <div className="offerings">
                      {this.state.hostel?.[0]?.OtherFacilities?.HostelFacilities.map(
                        (btn) => (
                          <button button className="offerings-button">
                            {btn}
                          </button>
                        )
                      )}
                    </div>
                  </div>
                )}{" "}
                <hr className="hr" />
              </>
              <>
                {this.state.hostel?.[0]?.OtherFacilities?.BedFacilities && (
                  <div className="offers">
                    <div className="offer-heading">
                      <img
                        src={bedImg}
                        alt="bed"
                        className="offer-heading-img"
                      />
                      <h3 style={{color:"#fd590c"}}>Bed Facilities</h3>
                    </div>

                    <div className="offerings">
                      {this.state.hostel?.[0]?.OtherFacilities?.BedFacilities.map(
                        (btn) => (
                          <button button className="offerings-button">
                            {btn}
                          </button>
                        )
                      )}
                    </div>
                  </div>
                )}{" "}
                <hr className="hr" />
              </>
              <>
                {this.state.hostel?.[0]?.StayFacilities && (
                  <div className="offers">
                    <div className="offer-heading">
                      <img
                        src={dormImg}
                        alt="dorm"
                        className="offer-heading-img"
                        style={{color:"#fd590c"}}
                      />
                      <h3 style={{color:"#fd590c"}}>Dorm Facilities</h3>
                    </div>

                    <div className="offerings">
                      {this.state.hostel?.[0]?.StayFacilities.map((btn) => (
                        <button button className="offerings-button">
                          {btn}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
                {/* <hr className='hr' /> */}
              </>
            </div>
          )}
          {this.state.activePlaceOffersTab === "Rules" && (
            <div className="offer-container ">
            <hr className="vertical-hr" />
            <>
              {this.state.hostel?.[0]?.OtherFacilities?.Rules?.checkin_checkout && (
                <div className="offers">
                  <div className="offer-heading">
                    <img
                      src={checkoutImg}
                      alt="checkin"
                      className="offer-heading-img"
                    />
                          <h3 id="rules-head"  >Check-In | Out Time</h3>
                  </div>

                  <div className="offerings" id="rules-sub" style={{fontWeight:"600"}}>
                    {this.state.hostel?.[0]?.OtherFacilities?.Rules?.checkin_checkout?.map((btn) => (
      // <h1
      // style={{
      //   fontFamily: "Montserrat",
      //   fontWeight: 600,
      //   fontSize: "20px",
      //   lineHeight: "24.38px",
      //   width: "169px",
      //   height: "18px",
      //   color: "black",
      //   //top: "215px", // Optional
      //   left: "0px", // Optional
      //   whiteSpace: "nowrap",
      // }}
      // >
      // {btn}
      // </h1>
      <h1 id="rules-sub" >
        {btn}
        </h1>
                    ))}
                  </div>
                </div>
              )}
              <hr className="hr" />
            </>
            <>
            {this.state.hostel?.[0]?.OtherFacilities?.Rules?.early_checkin && (
                <div className="offers">
                  <div className="offer-heading">
                    <img
                      src={checkinImg}
                      alt="checkout"
                      className="offer-heading-img"
                    />
                    <h3  style={{color:"#fd590c"}} >Early Check-In</h3>
                  </div>

                  <div className="offerings">
                    {this.state.hostel?.[0]?.OtherFacilities?.Rules?.early_checkin                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             ?.map((btn) => (
 <h1
    id="rules-sub"
>
 {btn}
</h1>
                    ))}
                  </div>
                </div>
              )}{" "}
              <hr className="hr" />
            </>
            <>
            {this.state.hostel?.[0]?.OtherFacilities?.Rules?.alcohol && (
                <div className="offers">
                  <div className="offer-heading">
                    <img
                      src={alcoholImg}
                      alt="alcohol"
                      className="offer-heading-img"
                    />
                    <h3  style={{color:"#fd590c"}} >Alcohol</h3>
                  </div>

                  <div className="offerings">
                    {this.state.hostel?.[0]?.OtherFacilities?.Rules?.alcohol?.map((btn) => (
 <h1 id="rules-sub" >
 {btn}
</h1>
                    ))}
                  </div>
                </div>
              )}{" "}
              <hr className="hr" />
            </>
            <>
            {this.state.hostel?.[0]?.OtherFacilities?.Rules?.smoking && (
                <div className="offers">
                  <div className="offer-heading">
                    <img
                      src={smokingImg}
                      alt="drugs"
                      className="offer-heading-img"
                    />
                    <h3  style={{color:"#fd590c"}} >Smoking</h3>
                  </div>

                  <div className="offerings">
                    {this.state.hostel?.[0]?.OtherFacilities?.Rules?.smoking?.map((btn) => (
 <h1 id="rules-sub"
>
 {btn}
</h1>
                    ))}
                  </div>
                </div>
              )}{" "}
              <hr className="hr" />
            </>
            <>
            {this.state.hostel?.[0]?.OtherFacilities?.Rules?.pets && (
                <div className="offers">
                  <div className="offer-heading">
                    <img
                      src={petsImg}
                      alt="drugs"
                      className="offer-heading-img"
                    />
                    <h3  style={{color:"#fd590c"}} >Pets</h3>
                  </div>

                  <div className="offerings">
                    {this.state.hostel?.[0]?.OtherFacilities?.Rules?.pets?.map((btn) => (
 <h1 id="rules-sub"
>
 {btn}
</h1>
                    ))}
                  </div>
                </div>
              )}{" "}
              <hr className="hr" />
            </>
            <>
            {this.state.hostel?.[0]?.OtherFacilities?.Rules?.age_restriction && (
                <div className="offers">
                  <div className="offer-heading">
                    <img
                      src={ageImg}
                      alt="food"
                      className="offer-heading-img"
                    />
                    <h3  style={{color:"#fd590c"}} >Age Restriction</h3>
                  </div>

                  <div className="offerings">
                    {this.state.hostel?.[0]?.OtherFacilities?.Rules?.age_restriction?.map((btn) => (
 <h1 id="rules-sub" style={{paddingLeft:"10px"}}
>
 {btn}
</h1>
                    ))}
                  </div>
                </div>
              )}
            </>
          </div>
          )}
        </div>
        </div>
        {/* Select Rooms */}
        <div id="select-rooms" className="main-container mt-5" ref={this.roomRef}>
          <div className="h2 text-black fw-bold mb-3">Select Rooms</div>
          {this.state.inventory.map((eachInventory) => {
            return (
              <div className="mb-3">
                <Card style={{ maxWidth: "900px" }} className="room-card">
                  <CardBody
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "self-start",
                      padding: "0",
                    }}
                  >
                    <div
                      className="d-flex gap-3 flex-xl-row flex-column  "
                      style={{ width: "100%" }}
                    >
                      {/* <img
                        src={
                          this.state.allImages.find((url) => {
                            const keyPart = url
                              ?.split("c_fill/")[1]
                              .split("?")[0];
                            const components = keyPart.split("_");
                            return (
                              components[0] === eachInventory.roomId &&
                              parseInt(components[1]) === eachInventory.hostelId
                            );
                          }) || { HostelRoom }
                        }
                        alt="hostelCo"
                        className="w-100"
                        style={{   
                          borderRadius: "0.5rem",
                          maxWidth: "130px",
                          objectFit: "cover",
                        }}
                      /> */}
                      <div
                        className=""
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div style={{ display: "block" }}>
                          <div className="room-name-heading">
                            {eachInventory.roomType}
                          </div>    
                          <div>
                            <div>
                              <div className="select-room-price">
                                <i
                                  className="bi bi-currency-rupee"
                                  style={{
                                    transform: "translateY(3px)",
                                  }}
                                ></i>
                                <span>{Math.round((eachInventory.cost - eachInventory.vendorDiscount ) - eachInventory.thcDiscount)}</span>
                                <span style={{ fontSize: "0.6em", marginLeft: "5px",fontWeight: "normal",color: "#666" }}> for {this.state.days} {this.state.days === 1 ? "night" : "nights"} +{(
                                      (eachInventory.cost - eachInventory.vendorDiscount ) *
                                      0.12
                                    )?.toFixed(2)} tax</span>
                              </div>
                              <div
                                className="text-black"
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                  fontWeight: "500",
                                  alignItems: "center",
                                  marginBottom: "5px",
                                }}
                              >
                                <div>
                                  <i className="bi bi-person text-black "></i>{" "}
                                  x1
                                </div>
                                <div>
                                  <i class="bi bi-moon"></i> {this.state.days}
                                  <span style={{paddingLeft:"0.5rem",paddingRight:"0.5rem"}}>x</span>
                                  <i
                                  className="bi bi-currency-rupee"
                                  style={{
                                    transform: "translateY(3px)",
                                  }}
                                ></i>
                                <span>{Math.round((((eachInventory.cost - eachInventory.vendorDiscount) -eachInventory.thcDiscount ) / this.state.days))}</span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="text-white px-3 py-1"
                              style={{
                                backgroundColor: "#2D7912",
                                borderRadius: "2rem",
                                width: "max-content",
                                boxShadow: "0px 4px 4px 0px #00000040",
                              }}
                            >
                              Only{" "}
                              {eachInventory.availableRooms -
                                eachInventory.bookedRooms}{" "}
                              beds available
                            </div>
                          </div>
                        </div>
                        {/* Add button */}
                        <div
                          className=" "
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {!this.state.bookingData[eachInventory.roomType] && (
                            <Button
                              onClick={() => roomAdded(eachInventory)}
                              style={{
                                boxShadow: "0px 2px 4px 0px #00000040",
                              }}
                              className="bg-white text-secondary-o px-4 fw-bold add-button border-1  "
                            >
                              Add
                            </Button>
                          )}

                          {this.state.bookingData[eachInventory.roomType] && (
                            <InputGroup className="border-0" style={{width:"max-content"}}>
                              <Button
                                style={{ borderRadius: "0.5rem" }}
                                onClick={() => roomRemoved(eachInventory)}
                                className="add-button bg-white text-dark-o border-0 py-2"
                                size="sm"
                              >
                                <i className="bi bi-dash"></i>
                              </Button>
                              <Input
                                className="text-center fw-bold  bg-transparent text-black border-0"
                                style={{ width: "40px" }}
                                placeholder="0"
                                value={
                                  this.state.bookingData[eachInventory.roomType]
                                    .totalRooms
                                }
                              />
                              <Button
                                style={{ borderRadius: "0.5rem" }}
                                onClick={() => roomAdded(eachInventory)}
                                className="add-button bg-white fw-bold text-dark-o border-0 py-2"
                                size="sm"
                              >
                                <i className="bi bi-plus-lg"></i>
                              </Button>
                            </InputGroup>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            );
          })}
        </div>

        <div className="reviews" ref={this.reviewsRef} >
<h1 className="review-heading">
  Reviews From Google
</h1>
<div className="slider-reviews">
<Slider {...smallDeviceSlider} ref={this.sliderRef}>  
  { this.state.reviews[0]?.reviews.map((eachReview) =>(
  <div className="user-review" style={{width:"20rem"}}>
    <img className="review-avatar" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" />
  <div className="user-review-text-div">
  <p className="review-para">{eachReview?.text?.text}</p>
<p className="user-review-name">
{eachReview?.AuthorName}
</p>
</div>
</div>
  ))
}
  </Slider>

  <div className="custom-arrow next " onClick={goToNext}>
       <i   style={{fontSize:"25px",color:'white'}} class="bi bi-caret-right-fill"></i>
    </div>

    <div className="custom-arrow prev" onClick={goToPrev}>
       <i  style={{fontSize:"25px",color:'white'}} class="bi bi-caret-left-fill"></i>
  </div>

</div>

        </div>
        <div className="map-div">
          <div className="Location-heading">Location</div>
          <div className="map-container">
          <iframe
            src={this.state.hostel[0]?.location_url}
            title="maps"
            width="100%"
            height="450"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          </div>

        </div>
        <Footer />
        <Modal
          scrollable
          size="xl"
          isOpen={this.state.isModal}
          toggle={() => this.setState({ isModal: false })}
        >
          <ModalHeader toggle={() => this.setState({ isModal: false })}>
            All Hostel Images
          </ModalHeader>
          <div className="toolbar" style={{ padding: "10px", display: "flex", gap: "10px", justifyContent: "center", marginTop: "30px"}}>
          <Button style={{ backgroundColor: '#FD590C', color: 'white',fontSize:"14px", borderRadius: '7px',...(this.state.isMobile ? {textAlign: "center",width:"80px"} : { textAlign: "center",minWdth: "120px" })}} onClick={this.handleScrollToCommonAreas}>Common Areas</Button>
          <Button style={{ backgroundColor: '#FD590C', color: 'white',fontSize:"14px", borderRadius: '7px',...(this.state.isMobile ? {textAlign: "center",width:"80px"} : { textAlign: "center",minWidth: "120px" }) }} onClick={this.handleScrollToBedroom}>Room</Button>
          <Button style={{ backgroundColor: '#FD590C', color: 'white',fontSize:"12px", borderRadius: '7px',...(this.state.isMobile ? {textAlign: "center",width:"80px"} : { textAlign: "center",minWidth: "120px" }) }} onClick={this.handleScrollToWashroom}>Washroom</Button>
          <Button style={{ backgroundColor: '#FD590C', color: 'white',fontSize:"14px",borderRadius: '7px',...(this.state.isMobile ? {textAlign: "center",width:"80px"} : { textAlign: "center",minWidth: "120px" })}} onClick={this.handleScrollToOthers}>Others</Button>
        </div>
          <ModalBody>
          <div className="row row-cols-1 row-cols-lg-3">
          {this.renderImageGrid(this.state.commonareasImg,this.commonareasRef, "Common Areas")}
              {this.renderImageGrid(this.state.bedroomImg,this.bedroomRef, "Room")}
              {this.renderImageGrid(this.state.washroomImg,this.washroomRef, "Washroom")}
              {this.renderImageGrid(this.state.othersImg,this.othersRef, "Others")}
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.isImageModalOpen}
          toggle={this.closeImageModal}
          size="lg"
          fullscreen
        >
          {/* <ModalHeader toggle={this.closeImageModal} /> */}
          <ModalBody className="d-flex justify-content-center align-items-center"   style={{
    backgroundColor: "black",
    height: "100vh", // Ensure full viewport height
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative", // So buttons position correctly
  }}>
        <button
      onClick={this.closeImageModal}
      style={{
        position: "absolute",
        top: "20px",
        left: "30px",
        background: "transparent", /* No shape */
        border: "none", /* No border */
        color: "white", /* Keep cross visible */
        fontSize: "40px", /* Adjust size */
        fontWeight: "light",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      ×
      <span style={{fontSize:"21px", marginLeft: "10px", marginTop: "5px"}}>Close</span>
    </button>
    {this.state.allImages.length > 1 && (
                  <button
                    onClick={this.handlePrev}
                    style={{
                      position: "absolute",
                      left: "2%",
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      border: "2px solid rgba(255, 255, 255, 0.6)", // Circular border
                      background: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "24px",
                      cursor: "pointer",
                      zIndex:10,
                    }}
                  >
                      {"<"}
                  </button>)}
                  <div
  style={{
    position: "absolute",
    top: "20px",
    left: "50%",
    transform: "translateX(-50%)", /* Centers the text */
    color: "white",
    fontSize: "21px",
    fontWeight: "light",
    background: "rgba(0, 0, 0, 0.5)", /* Slightly dark background */
    padding: "5px 10px",
    borderRadius: "8px",
  }}
>
  {this.state.selectedIndex + 1}  /  {this.state.allImages.length}
</div>
            <div style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
              {this.state.selectedImage && (
                <img
                  src={this.state.selectedImage}
                  alt="Preview"
                  style={{
                    width: this.state.isLaptop?"100%":"70%",
                    maxHeight: "80vh",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>

            {this.state.allImages.length > 1 && (
            <button
                    onClick={this.handleNext}
                    style={{
                      position: "absolute",
                      right: "2%",
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      border: "2px solid rgba(255, 255, 255, 0.6)", // Circular border
                      background: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "24px",
                      cursor: "pointer",
                      zIndex: "10",
                    }}
                  >
                      {">"}
                  </button>)}
          </ModalBody>
        </Modal>
        <div class="booking-section bg-light shadow p-3 d-flex justify-content-between align-items-center d-md-none">
          

          {/* booknow section */}
          <div className="d-flex justify-content-between " style={{width:"100%"}} >
            <div>
              <p class="mb-0 text-muted" style={{fontSize:"large"}}>Total Price</p>
              <h5 class="mb-0"><strong style={{fontSize:"larger"}}> {this.state.totalAmount.toFixed(2)}</strong>
              <span style={{fontSize:"medium",color:"#6e6e6e"}}>(inclu. of taxes)</span></h5>
            </div>
            <Button
              style={{ borderRadius: "0.5rem", backgroundColor: "#FD590C" }}
              onClick={()=>{
                if(Object.keys(this.state.bookingData).length ===0){
                  this.handleScrollToRooms()
                }else{
                  this.setState({ isPopupOpen: true });
                }
              }}
              className="w-80 mb-3 bg-dark-o"
            >
              Book Now
            </Button>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bookingData: state.booking.bookingData,
});

const mapDispatchToProps = {
  bookedHostel,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HostelPage)
);
