import React, { Component } from "react";
import { Button, Input } from "reactstrap";
import SelectSearch from "react-select-search";
import MobileDatePickerCalendar from "./MobileDatePickerCalendar";

class SearchBarWithOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: props.check || "",
      isCalendarVisible: false,
      fromDate: props.fromDate || new Date(),
      toDate: props.toDate || new Date(Date.now() + 86400000),
      searchInput: props.searchInput || "",
      hostelId: props.hostelId || null,
      otherSearch: props.otherSearch || [],
      openCalendar:false,
    };
  }

  handleSubmit = () => {
    const { fromDate, toDate, searchInput, hostelId, otherSearch } = this.state;
    if (!hostelId) {
      return;
    }

    const formatDates = (fromDate, toDate) => {
      const formatDate = (date) => {
        if (!date) return null; // Ensure date exists
           let formattedDate;
        if( typeof date == 'string'){
        const [day, month, year] = date.split('/');
         formattedDate = new Date(`${year}-${month}-${day}T12:00:00Z`); // Correct format
        }
        else if (date instanceof Date){
          formattedDate = date
        }

        if (isNaN(formattedDate)) {
          console.error("Invalid date:", date); // Debugging output
          return null;
        }
    
        return formattedDate.toISOString().split("T")[0]; // Returns YYYY-MM-DD
      };
    
      return {
        formattedFromDate: formatDate(fromDate),
        formattedToDate: formatDate(toDate),
      };
    };
    
    // Usage
    const { formattedFromDate, formattedToDate } = formatDates(fromDate, toDate);

    var temp = otherSearch.join(",");
    if (hostelId.hostelid === "") {
      window.location.href = `/search?city=${searchInput}&from=${formattedFromDate}&to=${formattedToDate}&otherSearch=${temp}`;
    } else {
      window.location.href = `/hostel?hostel=${hostelId.hostelid}&from=${
        formattedFromDate}&to=${formattedToDate}&otherSearch=${temp}`;
    }
  };

  toggleCalendar = () => {
    this.setState((prevState) => ({
      isCalendarVisible: !prevState.isCalendarVisible,
    }));
  };
  onChange = (dates) => {
    console.log(dates)
    this.setState({
      fromDate: dates.fromDate,
      toDate: dates.toDate,
    });
    this.setState({openCalendar:false})
  };

   formatDate = (date) => {
    if (!date) return "";
    
    if (typeof date === "string") {
      return date.split("T")[0]; // If it's already a string, just return the date part
    }
  
    if (date instanceof Date) {
      return date.toISOString().split("T")[0]; // Convert Date object to string and format it
    }
  
    return "";
  };

  handleSearchChange = (value, hostelid) => {
    if (!hostelid) return;

    this.setState((prevState) => ({
      searchInput: hostelid.type !== "word" ? value : "",
      hostelId: hostelid,
      check: value,
      otherSearch: [...prevState.otherSearch, value],
    }));
  };

  render() {
    const { isCalendarVisible, fromDate, toDate ,openCalendar } = this.state; 
    const handleCalendar =() =>{
      console.log("in function")
      this.setState({openCalendar:false})
  }

    return (
      <div
        className="mobile-searchbar justify-content-center align-items-center flex-column"
        style={{
          width: "100vw",
          marginTop: "-7.5rem",
          backgroundColor: "#00000059",
          padding: "10px",
          backgroundColor:"transparent",
          position:"relative"
        }}
      >
          <p className="slider-image-para" style={{ fontSize: "0.8rem" ,position:"absolute", top:"-6rem"  }}>
         Craziest <span style={{fontWeight:"700" ,fontSize: "0.89rem" }}>Backpacker Hostel </span>
        </p>  
        <p className="slider-image-para" style={{ fontSize: "0.9rem" ,position:"absolute", top:"10px"  }}>
          Now in Goa, Pondicherry & Mysore 
        </p>  

        <div
          style={{
            backgroundColor: "#f9f9f9",
            width: "80%", 
            justifyContent: "space-between",
            marginTop:"-5rem",
            marginBottom:"5rem"
          }}
          className="d-flex p-1 shadow-sm rounded-pill"
        >
          <div
            className=" search-bar-mobile"

          >
            <div className="d-flex flex-column flex-grow-1 search-bar-inner"     >
              <div style={{ width: "8rem" }}>
                <SelectSearch
                  options={
                    this.props.allProperties.sort((a, b) => {
                      const categoryA =
                        a.type === "location" ? 1 : a.type === "hostel" ? 2 : 3;
                      const categoryB =
                        b.type === "location" ? 1 : b.type === "hostel" ? 2 : 3;
                      return categoryA - categoryB;
                    }) || []
                  }
                  name="searchInput"
                  search
                  placeholder="Where are you going?"
                  onChange={this.handleSearchChange}
                  filterOptions={[
                    (options, query) => {
                      if (query.length < 2) return [];
                      return options.filter((option) =>
                        option.name
                          .toLowerCase()
                          .startsWith(query.toLowerCase())
                      );
                    },
                  ]}
                />
              </div>

              <div
                className="d-flex align-items-center"
                style={{ paddingLeft: "1rem" }}
              >
                <Input
                  min={new Date().toISOString().split("T")[0]}
                  style={{
                    padding: 0,
                    fontSize: "small",
                    position: "relative",
                    zIndex: "2",
                  }}
                  className="border-0 bg-light"
                  value={this.formatDate(fromDate)}
                  placeholder="From"
                  name="fromDate"
                  type="text"
                  onChange={(e) =>
                    this.setState({ fromDate: new Date(e.target.value) })
                  }
                  onClick={() =>this.setState({openCalendar:true})}
                  readOnly
                />
                <Input
                  min={this.formatDate(fromDate)}
                  style={{ padding: 0, fontSize: "small" }}
                  className="border-0 bg-light"
                  value={this.formatDate(toDate)}
                  placeholder="To"
                  name="toDate"
                  type="text"
                  onClick={() =>this.setState({openCalendar:true})}
                  onChange={(e) =>
                    this.setState({ toDate: new Date(e.target.value) })
                  }
                />

        {
          this.state.openCalendar && 
            <MobileDatePickerCalendar onChange={this.onChange}  setOpenCalendar={handleCalendar} openCalendar={this.state.openCalendar}/>
        }
              </div>
            </div>
            <div></div>
          </div>
          <Button
            onClick={this.handleSubmit}
            size="sm"
            className="book-button d-flex d-md-none"
            style={{
              backgroundColor: "#f9f9f9",
              zIndex: "2",
              alignItems: "center",
              padding: "0.25rem 0.5rem",
            }}
          >
            <i
              className="bi bi-search"
              style={{ color: "#FD590C", fontSize: "large" }}
            ></i>
          </Button>
        </div>
      </div>
    );
  }
}

export default SearchBarWithOptions;
